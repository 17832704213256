import { Button } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

function FilterButton({ icon, title, fn }) {
    const { globalLoading } = useSelector((state) => state.downloadFilter);

    return (
        <Button
            onClick={fn}
            loading={globalLoading}
            size="large"
            className="filter-btn"
            icon={icon}
            type="primary"
        >
            {title}
        </Button>
    );
}

export default FilterButton;
