import { useSelector } from 'react-redux';

const useAuth = () => {
    const auth = useSelector((state) => state.auth);

    function isTokenExpired(token) {
        const base64Url = token.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
                .join('')
        );

        const { exp } = JSON.parse(jsonPayload);
        const expired = Date.now() >= exp * 1000;
        return expired;
    }

    if (auth?.accessToken && auth?.user && !isTokenExpired(auth?.accessToken)) {
        return true;
    }
    return false;
};

export default useAuth;
