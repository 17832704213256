/* eslint-disable prettier/prettier */
import { CloudDownloadOutlined } from '@ant-design/icons';
import { Col, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    downloadEndDate,
    downloadStartDate,
    reportType,
    setReport
} from '../../redux/features/filter/downloadReportFilterSlice';
import DateRange from './DateRange';
import FilterButton from './FilterButton';

function DownloadFilter({surveyDataFetch}) {
    const dispatch = useDispatch();

    const startDateFn = (_, dateString) => {
        dispatch(downloadStartDate(dateString));
    };

    const endDateFn = (_, dateString) => {
        dispatch(downloadEndDate(dateString));
    };

    const {
        user: { roles },
    } = useSelector((state) => state.auth);

    const [filterItems, setFilterItems] = useState([{
        label: 'PMM Maintenance Report',
        value: 'getPmmMaintenanceReport',
        }]);

    useEffect(() => {
        if (roles === 'HEDML') {
            setFilterItems((prev) => [
                {
                    label: 'Geo Location Report',
                    value: 'geoLocationReport',
                    },
                    {
                    label: 'Merchandising Opportunity Planogram Report',
                    value: 'getMerchandisingOppurtinityPlanogramReport',
                    },
                    {
                    label: 'Merchandising Opportunity PMM Report',
                    value: 'getMerchandisingOppurtinityPMMReport',
                    },
                    {
                    label: 'Merchandising Opportunity POSM Report',
                    value: 'getMerchandisingOppurtinityPOSMReport',
                    },
                    {
                    label: 'Merchandising Presence Planogram Report',
                    value: 'getMerchandisingPresencePlanogramReport',
                    },
                    {
                    label: 'Merchandising Presence PMM Maintenance Report',
                    value: 'getMerchandisingPresencePMMMaintenanceReport',
                    },
                    {
                    label: 'Merchandising Presence PMM Report',
                    value: 'getMerchandisingPresencePMMReport',
                    },
                    {
                    label: 'Merchandising Presence POSM Report',
                    value: 'getMerchandisingPresencePOSMReport',
                    },
                    {
                    label: 'MS Evaluation Report',
                    value: 'getMSEvaluationReport',
                    },
                    {
                    label: 'MC Evaluation Report',
                    value: 'getMCEvaluationReport',
                    },
                    {
                    label: 'PMM AI Report',
                    value: 'getPmmAiReport',
                    },
                    {
                    label: 'Planogram AI Report',
                    value: 'getPlanogramAiReport',
                    },
                    {
                    label: 'POSM AI Report',
                    value: 'getPosmAiReport',
                    },
                    {
                    label: 'SOV AI Report',
                    value: 'getSovAiReport',
                    },
                    ...prev
            ]);
        }
    }, [roles])

    return (
        <>
            <DateRange startFn={startDateFn} endFn={endDateFn} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    placeholder="Report Type"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(v) => dispatch(reportType(v))}
                    options={[...filterItems]}
                />
            </Col>
            {roles === 'HEDML' ? (
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    placeholder="Report"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(v) => dispatch(setReport(v))}
                    options={[
                        {
                        label: 'Regular Report',
                        value: 'regularReport',
                        },
                        {
                        label: 'Previous Report',
                        value: 'previousReport',
                        },
                    ]}
                />
            </Col>
            ) : null}
            
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <FilterButton fn={surveyDataFetch} icon={<CloudDownloadOutlined />} title="Export Data" />
            </Col>
        </>
    );
}

export default DownloadFilter;
