/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-plusplus */
import { Button, Col, Row, Select, Space, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import TagComponent from '../../pages/TagComponent';

function InformationChange({ employeeCode, pointData, setPointData }) {
    const [loading, setLoading] = useState(false);
    const { accessToken } = useSelector((state) => state.auth);
    const [pointList, setPointList] = useState([]);

    useEffect(() => {
        setLoading(true);
        (async function () {
            try {
                const resPromise = await fetch(
                    `https://api.hedigital.app/api/mappings/getMappingData/${employeeCode}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                const result = await resPromise.json();
                const resData = result?.map((d) => ({
                    ...d,
                    routeSection: d.routeSection.split(',').map((s) => s.trim()),
                }));
                setPointData(resData);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [employeeCode, accessToken]);

    // get point list
    useEffect(() => {
        setLoading(true);
        async function getPointList() {
            try {
                const res = await fetch('https://api.hedigital.app/api/mappings/getPoints', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const result = await res.json();
                const mappedPoint = result.points.map((point) => ({ label: point, value: point }));
                setPointList(mappedPoint);
            } catch (error) {
                alert(error.message);
            } finally {
                setLoading(false);
            }
        }
        getPointList();
    }, [accessToken]);

    // new point add function
    const addNewData = () => {
        setPointData((prev) => prev.concat({ point: '', routeSection: [''] }));
    };
    // point list change function
    const onHandleChange = (v, i) => {
        setPointData((prev) =>
            prev.map((item, idx) => (i === idx ? { ...item, point: v } : { ...item }))
        );
    };
    return (
        <>
            {loading && pointData.length ? (
                <div
                    style={{
                        display: 'grid',
                        placeItems: 'center',
                        width: '100%',
                        padding: '50px 0',
                    }}
                >
                    <Spin size="large" />
                </div>
            ) : (
                <>
                    <Row>
                        {pointData?.map((doc, i) => (
                            <Col key={i} span={24}>
                                <Space style={{ marginBottom: '20px' }}>
                                    <div key={doc.pointName}>
                                        <p style={{ margin: '0' }}>
                                            Point <sup style={{ color: 'red' }}>*</sup>
                                        </p>
                                        <Select
                                            defaultValue={doc?.point || 'Barishal'}
                                            showSearch
                                            placeholder="Select a person"
                                            optionFilterProp="children"
                                            onChange={(v) => onHandleChange(v, i)}
                                            // onSearch={onSearch}
                                            style={{ minWidth: '150px' }}
                                            filterOption={(input, option) =>
                                                (option?.label ?? '')
                                                    .toLowerCase()
                                                    .includes(input.toLowerCase())
                                            }
                                            options={pointList}
                                        />
                                    </div>
                                    <div>
                                        <p style={{ margin: '0' }}>
                                            Route/Section <sup style={{ color: 'red' }}>*</sup>
                                        </p>
                                        <TagComponent
                                            key={doc.pointName + i}
                                            data={pointData}
                                            setData={setPointData}
                                            idx={i}
                                        />
                                    </div>
                                </Space>
                            </Col>
                        ))}
                    </Row>
                    <div style={{ marginTop: '20px' }}>
                        <Button onClick={addNewData} type="primary">
                            Add New
                        </Button>
                    </div>
                </>
            )}
        </>
    );
}

export default InformationChange;
