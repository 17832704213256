import React from 'react';
import { Route, Routes } from 'react-router-dom';
import DashboardChart from './components/Chart/DashboardChart';
import CmMapping from './components/CmMapping/CmMapping';
import Dashboard from './components/Layout/Layout';
import PmmMaintenance from './components/PmmMaintenance/PmmMaintenance';
import Survey from './components/Survey/Survey';
import DownloadReport from './components/downloadReport/DownloadReport';
import useAuthCheck from './hook/useAuthCheck';
import Login from './pages/Login';
import PrivateRoute from './pages/PrivateRoute';

function App() {
    const authChecked = useAuthCheck();

    return !authChecked ? (
        <div>
            <h2>Checking authentication....</h2>
        </div>
    ) : (
        <Routes>
            <Route
                path="/"
                element={
                    <PrivateRoute>
                        <Dashboard>
                            <DashboardChart />
                        </Dashboard>
                    </PrivateRoute>
                }
            />
            <Route
                path="/survey"
                element={
                    <PrivateRoute>
                        <Dashboard>
                            <Survey />
                        </Dashboard>
                    </PrivateRoute>
                }
            />
            <Route
                path="/pmm-maintenance"
                element={
                    <PrivateRoute>
                        <Dashboard>
                            <PmmMaintenance />
                        </Dashboard>
                    </PrivateRoute>
                }
            />
            <Route
                path="/cm-mapping"
                element={
                    <PrivateRoute>
                        <Dashboard>
                            <CmMapping />
                        </Dashboard>
                    </PrivateRoute>
                }
            />
            <Route
                path="/download-report"
                element={
                    <PrivateRoute>
                        <Dashboard>
                            <DownloadReport />
                        </Dashboard>
                    </PrivateRoute>
                }
            />
            <Route path="/login" element={<Login />} />
        </Routes>
    );
}
export default App;
