/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startDate: "",
  endDate: "",
  locationMatch: ["Yes", "No"],
  outletCode: "",
  surveyMsCode: "",
  surveyCmCode: "",
  msEvaluation: ["Yes", "No"],
  presence: ["Yes", "No"],
  opportunity: ["Yes", "No"],
  maintenance: ["Yes", "No"],
  ownerName: [],
  materialType: [],
  msCallType: [],
  evaluationType: [],
  aiEvaluation: [],
  mcEvaluation: ["Yes", "No"],
  surveyRouteSection: "",
  priority: [],
  channel: [],
  subChannel: [],
};

const surveyFilterSlice = createSlice({
  name: "surveyFilterSlice",
  initialState,
  reducers: {
    surveyStartDate: (state, action) => {
      state.startDate = action.payload;
    },
    surveyEndDate: (state, action) => {
      state.endDate = action.payload;
    },
    surveyLocationMatch: (state, action) => {
      state.locationMatch = action.payload;
    },
    surveyOutletCode: (state, action) => {
      state.outletCode = action.payload;
    },
    surveyMsCode: (state, action) => {
      state.surveyMsCode = action.payload;
    },
    surveyCmCode: (state, action) => {
      state.surveyCmCode = action.payload;
    },
    surveyEvaluation: (state, action) => {
      state.msEvaluation = action.payload;
    },
    setPresence: (state, action) => {
      state.presence = action.payload;
    },
    setOpportunity: (state, action) => {
      state.opportunity = action.payload;
    },
    setMaintenance: (state, action) => {
      state.maintenance = action.payload;
    },
    setOwnerName: (state, action) => {
      state.ownerName = action.payload;
    },
    setMaterialType: (state, action) => {
      state.materialType = action.payload;
    },
    setMsCallType: (state, action) => {
      state.msCallType = action.payload;
    },
    setEvaluationType: (state, action) => {
      state.evaluationType = action.payload;
    },
    setAiEvaluation: (state, action) => {
      state.aiEvaluation = action.payload;
    },
    setMcEvaluation: (state, action) => {
      state.mcEvaluation = action.payload;
    },
    setSurveyRouteSection: (state, action) => {
      state.surveyRouteSection = action.payload;
    },
    setPriority: (state, action) => {
      state.priority = action.payload;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setSubChannel: (state, action) => {
      state.subChannel = action.payload;
    },
    resetSurveyFilter: (state, action) => {
      state.startDate = "";
      state.endDate = "";
      state.locationMatch = "";
      state.outletCode = "";
      state.surveyMsCode = "";
      state.surveyCmCode = "";
      state.msEvaluation = "";
      state.presence = "";
      state.opportunity = "";
      state.maintenance = "";
      state.ownerName = "";
      state.materialType = "";
      state.msCallType = "";
      state.evaluationType = "";
      state.aiEvaluation = "";
      state.mcEvaluation = "";
      state.surveyRouteSection = "";
      state.priority = "";
      state.channel = "";
      state.subChannel = "";
    },
  },
});

export default surveyFilterSlice.reducer;
export const {
  surveyStartDate,
  surveyEndDate,
  surveyLocationMatch,
  surveyOutletCode,
  surveyMsCode,
  surveyCmCode,
  surveyEvaluation,
  setAiEvaluation,
  setEvaluationType,
  setMaintenance,
  setOwnerName,
  setMaterialType,
  setMsCallType,
  setOpportunity,
  setPresence,
  setMcEvaluation,
  setSurveyRouteSection,
  setPriority,
  setChannel,
  setSubChannel,
  resetSurveyFilter,
} = surveyFilterSlice.actions;
