/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
import { Pagination, Spin, theme } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { resetMMFilter } from "../../redux/features/filter/mmFilterSlice";
import { resetSurveyFilter } from "../../redux/features/filter/surveyFilterSlice";
import NoResult from "../../ui/NoResult";
import Filter from "../Filter/Filter";
import SurveyItem from "./SurveyItem";

function Survey() {
  const dispatch = useDispatch();
  // survey filter data
  const { region, area, territory, distributionHouse, point } = useSelector(
    state => state.mmFilter
  );
  const {
    startDate,
    endDate,
    locationMatch,
    msEvaluation,
    mcEvaluation,
    outletCode,
    surveyMsCode,
    surveyCmCode,
    presence,
    opportunity,
    maintenance,
    ownerName,
    materialType,
    msCallType,
    evaluationType,
    aiEvaluation,
    surveyRouteSection,
    priority,
    channel,
    subChannel,
  } = useSelector(state => state.surveyFilter);

  const { accessToken } = useSelector(state => state.auth);

  const [loading, setLoading] = useState(false);

  const { pathname } = useLocation();

  // pagination
  const [totalShowPage, setTotalPageShow] = useState(10);
  const [currentPage, setCurrentPageShow] = useState(1);

  // data
  const [data, setData] = useState([]);

  // reset existing filter
  useEffect(() => {
    dispatch(resetMMFilter());
    dispatch(resetSurveyFilter());
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    fetch(
      `https://api.hedigital.app/api/surveys/getSurveyData?page=1&limit=${totalShowPage}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    )
      .then(res => res.json())
      .then(result => {
        setData(result);
        setLoading(false);
      });
  }, []);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  // aiEvaluation: [],

  // survey query data
  const getSurveyQueryData = () => {
    const bodyData = {
      locationMatch: locationMatch.length <= 0 ? ["Yes", "No"] : locationMatch,
      evaluation: msEvaluation.length <= 0 ? ["Yes", "No"] : msEvaluation,
      mcEvaluation: mcEvaluation.length <= 0 ? ["Yes", "No"] : mcEvaluation,
      presence: presence.length <= 0 ? ["Yes", "No"] : presence,
      opportunity: opportunity.length <= 0 ? ["Yes", "No"] : opportunity,
      maintenance: maintenance.length <= 0 ? ["Yes", "No"] : maintenance,
    };

    if (region.length) {
      bodyData.region = region?.map(r => r.label);
    }
    if (area.length) {
      bodyData.area = area?.map(r => r.label);
    }
    if (territory.length) {
      bodyData.territory = territory?.map(r => r.label);
    }
    if (distributionHouse.length) {
      bodyData.distributionHouse = distributionHouse?.map(r => r.label);
    }
    if (point.length) {
      bodyData.point = point?.map(r => r.label);
    }
    if (surveyMsCode) {
      bodyData.supervisorCode = surveyMsCode;
    }
    if (surveyCmCode) {
      bodyData.cmCode = surveyCmCode;
    }
    if (outletCode) {
      bodyData.outletCode = outletCode;
    }
    if (ownerName.length) {
      bodyData.ownerName = ownerName;
    }
    if (materialType.length) {
      bodyData.materialType = materialType;
    }
    if (msCallType.length) {
      bodyData.msCallType = msCallType;
    }
    if (evaluationType.length) {
      bodyData.evaluationType = evaluationType;
    }
    if (aiEvaluation.length) {
      bodyData.aiEvaluation = aiEvaluation;
    }
    if (priority.length) {
      bodyData.priority = priority;
    }
    if (channel.length) {
      bodyData.channel = channel;
    }
    if (subChannel.length) {
      bodyData.subChannel = subChannel;
    }
    if (surveyRouteSection) {
      bodyData.surveyRouteSection = surveyRouteSection;
    }
    if (startDate || endDate) {
      bodyData.createdAt = [
        moment(startDate).toJSON() || "",
        moment(endDate).toJSON() || "",
      ];
    }

    return bodyData;
  };

  const fetchSurveyQueryData = async (page, totalShow, clean) => {
    setLoading(true);
    try {
      if (clean === "cleanShowResultOnPage") {
        setCurrentPageShow(1);
        setTotalPageShow(10);
      }
      setLoading(true);
      const fetchData = await fetch(
        `https://api.hedigital.app/api/surveys/getSurveyData?page=${page}&limit=${totalShow}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(getSurveyQueryData()),
        }
      );
      const resultData = await fetchData.json();
      setData(resultData);
    } catch (error) {
      setLoading(true);
    } finally {
      setLoading(false);
    }
  };

  const onChange = (pageNumber, totalPageChange) => {
    setTotalPageShow(() => totalPageChange);
    setCurrentPageShow(pageNumber);
    fetchSurveyQueryData(pageNumber, totalPageChange);
  };

  return (
    <>
      <div style={{ margin: "16px 0" }}>
        <Filter surveyDataFetch={fetchSurveyQueryData} pathname={pathname} />
      </div>
      <div
        style={{
          padding: 15,
          minHeight: 400,
          background: colorBgContainer,
        }}
      >
        {!loading ? (
          <>
            {data?.data?.length <= 0 ? (
              <NoResult />
            ) : (
              <>
                {data?.data?.map((item, i) => (
                  <SurveyItem loading={loading} data={item} key={i} />
                ))}
              </>
            )}
          </>
        ) : (
          <div
            style={{
              minHeight: "200px",
              width: "100%",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Spin size="large" />
          </div>
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Pagination
            size="large"
            pageSize={totalShowPage}
            showSizeChanger
            showQuickJumper
            current={currentPage}
            defaultCurrent={1}
            total={data?.totalCount}
            onChange={onChange}
            showTotal={(total, range) =>
              `${range[0]}-${range[1]} of ${total} items`
            }
          />
        </div>
      </div>
    </>
  );
}

export default Survey;
