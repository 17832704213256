/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
import React from 'react';
import Chart from 'react-google-charts';

function PieChartGraph({ pieData }) {
    const total = (Number(pieData?.covered) || 0) + (Number(pieData?.uncovered) || 0);
    const data = [
        ['Task', 'Covered and Uncovered'],
        ['Covered', pieData?.covered || 0],
        ['Uncovered', pieData?.uncovered || 0],
    ];
    const options = {
        legend: {
            position: 'bottom',
        },
        title: `Total Outlet Count: ${total}`,
    };
    return (
        <div style={{ height: '100%' }}>
            <Chart chartType="PieChart" data={data} options={options} width="100%" height="400px" />
        </div>
    );
}

export default PieChartGraph;
