/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import { Col, Divider, Image, Row, Table } from 'antd';
import React from 'react';

function AiResult({ info }) {
    const planogramAiResult = info?.planogram?.map(({ imageURLs, planogramOwner }, i) => {
        const {
            aiResult: { category, materialCounts },
            url,
        } = imageURLs[0];
        return {
            url,
            planogram: i + 1,
            owner: planogramOwner,
            detectedOwner: category || '',
            materialCounts: materialCounts || [],
        };
    });
    const pmmAiResult = info?.pmm?.map(({ imageURLs, pmmName, pmmOwner, pmmType }, i) => {
        const {
            aiResult: { category, materialCounts },
            url,
        } = imageURLs[1];
        return {
            url,
            pmm: i + 1,
            owner: pmmOwner,
            pmmName,
            pmmType,
            detectedOwner: category || '',
            materialCounts: materialCounts || [],
        };
    });
    const posmAiResult = info?.posm?.map(({ imageURLs, posmOwner, posmType }, i) => {
        const {
            aiResult: { category, posm, blanks },
            url,
        } = imageURLs[0];
        return {
            url,
            posm: i + 1,
            owner: posmOwner,
            posmType,
            detectedOwner: category || '',
            detectedType: posm || [],
            materialCounts: blanks || [],
        };
    });

    function companyByMaterialId(materialId) {
        return {
            1: 'BAT',
            4: 'BAT',
            5: 'BAT',
            6: 'BAT',
            9: 'BAT',
            10: 'BAT',
            11: 'BAT',
            12: 'BAT',
            13: 'BAT',
            14: 'BAT',
            15: 'BAT',
            16: 'BAT',
            17: 'BAT',
            18: 'BAT',
            19: 'BAT',
            21: 'BAT',
            22: 'BAT',
            23: 'BAT',
            24: 'BAT',
            25: 'BAT',
            26: 'BAT',
            27: 'BAT',
            28: 'BAT',
            29: 'BAT',
            51: 'BAT',
            54: 'BAT',
            55: 'BAT',
            57: 'BAT',
            59: 'BAT',
            60: 'BAT',
            61: 'BAT',
            62: 'BAT',
            64: 'BAT',
            67: 'BAT',
            68: 'BAT',
            69: 'BAT',
            70: 'BAT',
            71: 'BAT',
            72: 'BAT',
            73: 'BAT',
            74: 'BAT',
            75: 'BAT',
            83: 'BAT',
            93: 'BAT',
            150: 'AKTC',
            152: 'AKTC',
            153: 'AKTC',
            154: 'AKTC',
            156: 'BAT',
            157: 'BAT',
            158: 'BAT',
            159: 'PMI',
            165: 'JTI',
            166: 'JTI',
            167: 'JTI',
            168: 'JTI',
            169: 'JTI',
            170: 'JTI',
            171: 'JTI',
            172: 'JTI',
            173: 'JTI',
            174: 'JTI',
            175: 'JTI',
            179: 'JTI',
            50001: 'PMI',
            50002: 'PMI',
            50003: 'PMI',
            50004: 'JTI',
            50005: 'JTI',
            50006: 'JTI',
            50007: 'JTI',
            50008: 'JTI',
            50009: 'JTI',
            50010: 'AKTC',
            50011: 'AKTC',
            50012: 'AKTC',
            50015: 'AKTC',
            50016: 'AKTC',
            50017: 'JTI',
            50018: 'JTI',
            50019: 'AKTC',
            50020: 'AKTC',
            50021: 'AKTC',
            50022: 'JTI',
            50023: 'JTI',
            50024: 'JTI',
            50025: 'AKTC',
        }[materialId];
    }

    let totalPosmCount = 0;
    let totalBlanksCount = 0;

    const posmRes = posmAiResult.reduce(
        (accumulutor, current) => {
            if (current.detectedType.length > 0) {
                current.detectedType.forEach((curr) => {
                    const company = companyByMaterialId(curr.mat_id);
                    if (accumulutor.posm[company]) {
                        accumulutor.posm[company] += curr.mat_qty;
                    } else {
                        accumulutor.posm[company] = curr.mat_qty;
                    }
                    totalPosmCount += curr.mat_qty;
                });
            } else {
                current.materialCounts.forEach((curr) => {
                    const company = companyByMaterialId(curr.mat_id);
                    if (accumulutor.blanks[company]) {
                        accumulutor.blanks[company] += curr.mat_qty;
                    } else {
                        accumulutor.blanks[company] = curr.mat_qty;
                    }
                    totalBlanksCount += curr.mat_qty;
                });
            }
            return accumulutor;
        },
        { posm: {}, blanks: {} }
    );

    const planogramRes = planogramAiResult.reduce(
        (accumulutor, current) => {
            current.materialCounts.forEach((curr) => {
                const company = companyByMaterialId(curr.mat_id);
                if (accumulutor.blanks[company]) {
                    accumulutor.blanks[company] += curr.mat_qty;
                } else {
                    accumulutor.blanks[company] = curr.mat_qty;
                }
                totalBlanksCount += curr.mat_qty;
            });
            return accumulutor;
        },
        { blanks: {} }
    );

    const pmmRes = pmmAiResult.reduce(
        (accumulutor, current) => {
            current.materialCounts.forEach((curr) => {
                const company = companyByMaterialId(curr.mat_id);
                if (accumulutor.blanks[company]) {
                    accumulutor.blanks[company] += curr.mat_qty;
                } else {
                    accumulutor.blanks[company] = curr.mat_qty;
                }
                totalBlanksCount += curr.mat_qty;
            });
            return accumulutor;
        },
        { blanks: {} }
    );

    const result = [
        ...Object.entries(planogramRes.blanks),
        ...Object.entries(pmmRes.blanks),
        ...Object.entries(posmRes.blanks),
    ].reduce((acc, [k, v]) => {
        if (acc[k]) {
            acc[k].count += v;
            acc[k].size += v * 0.05;
            acc[k].countPercent += (v / (totalBlanksCount + totalPosmCount)) * 100;
            acc[k].sizePercent +=
                ((v * 0.05) / (totalBlanksCount * 0.05 + totalPosmCount * 1.3)) * 100;
        } else {
            acc[k] = {
                count: v,
                size: v * 0.05,
                countPercent: (v / (totalBlanksCount + totalPosmCount)) * 100,
                sizePercent: ((v * 0.05) / (totalBlanksCount * 0.05 + totalPosmCount * 1.3)) * 100,
            };
        }
        return acc;
    }, {});

    const finalResult = [...Object.entries(posmRes.posm)].reduce((acc, [k, v]) => {
        if (acc[k]) {
            acc[k].count += v;
            acc[k].size += v * 1.3;
            acc[k].countPercent += (v / (totalBlanksCount + totalPosmCount)) * 100;
            acc[k].sizePercent +=
                ((v * 1.3) / (totalBlanksCount * 0.05 + totalPosmCount * 1.3)) * 100;
        } else {
            acc[k] = {
                count: v,
                size: v * 1.3,
                countPercent: (v / (totalBlanksCount + totalPosmCount)) * 100,
                sizePercent: ((v * 1.3) / (totalBlanksCount * 0.05 + totalPosmCount * 1.3)) * 100,
            };
        }
        return acc;
    }, result);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'mat_name',
            key: 'mat_name',
        },
        {
            title: ' AI Quantity',
            dataIndex: 'mat_qty',
            key: 'mat_qty',
        },
    ];
    const byAreaColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
        },
        {
            title: 'Percent',
            dataIndex: 'percent',
            key: 'percent',
        },
    ];
    const areaData = [
        {
            _id: '1',
            name: 'BATB',
            size: '15in',
            percent: '80%',
        },
        {
            _id: '2',
            name: 'JTI',
            size: '4in',
            percent: '20%',
        },
    ];
    const byCountColumns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Count',
            dataIndex: 'count',
            key: 'count',
        },
        {
            title: 'Percent',
            dataIndex: 'percent',
            key: 'percent',
        },
    ];
    const countData = [
        {
            _id: '1',
            name: 'BATB',
            count: '80',
            percent: '80%',
        },
        {
            _id: '2',
            name: 'JTI',
            count: '20',
            percent: '20%',
        },
    ];

    return (
        <>
            <Row gutter={[10, 10]} style={{ marginTop: '10px' }}>
                {pmmAiResult?.length ? (
                    <Col sm={12} lg={8}>
                        {pmmAiResult?.map(
                            (
                                {
                                    pmm,
                                    owner,
                                    detectedOwner,
                                    pmmName,
                                    pmmType,
                                    materialCounts,
                                    url,
                                },
                                i
                            ) => (
                                <div
                                    key={i + 1}
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        marginTop: '5px',
                                    }}
                                >
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div className="ai-info">
                                            <p>
                                                <span>PMM : </span> PMM {pmm}
                                            </p>
                                            <p>
                                                <span>Owner : </span> {owner}
                                            </p>
                                            <p>
                                                <span>Detected Owner : </span> {detectedOwner}
                                            </p>
                                            <p>
                                                <span>PMM Name : </span> {pmmName}
                                            </p>
                                            <p>
                                                <span>PMM Type : </span> {pmmType}
                                            </p>
                                        </div>
                                        <div>
                                            <Image
                                                src={url}
                                                width={50}
                                                style={{ borderRadius: '5px' }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: '600', margin: '6px 0 2px 0' }}>
                                            Blanks:
                                        </p>
                                        <Table
                                            rowKey="_id"
                                            size="small"
                                            dataSource={materialCounts || []}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </Col>
                ) : null}

                {posmAiResult?.length ? (
                    <Col sm={12} lg={8}>
                        {posmAiResult?.map(
                            (
                                {
                                    posm,
                                    owner,
                                    detectedOwner,
                                    posmType,
                                    url,
                                    materialCounts,
                                    detectedType,
                                },
                                i
                            ) => (
                                <div
                                    key={i + 1}
                                    style={{
                                        backgroundColor: 'white',
                                        padding: '5px',
                                        borderRadius: '5px',
                                        marginTop: '5px',
                                    }}
                                >
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div className="ai-info">
                                            <p>
                                                <span>POSM : </span> POSM {posm}
                                            </p>
                                            <p>
                                                <span>Type : </span> {posmType}
                                            </p>
                                            <p>
                                                <span>Owner Name : </span> {owner}
                                            </p>

                                            <p>
                                                <span>Detected Owner : </span> {detectedOwner}
                                            </p>
                                        </div>
                                        <div>
                                            <Image
                                                src={url}
                                                width={50}
                                                style={{ borderRadius: '5px' }}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <p style={{ fontWeight: '600', margin: '6px 0 2px 0' }}>
                                            POSM:
                                        </p>
                                        <Table
                                            rowKey="_id"
                                            size="small"
                                            dataSource={detectedType || []}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </div>

                                    <div>
                                        <p style={{ fontWeight: '600', margin: '6px 0 2px 0' }}>
                                            Blanks:
                                        </p>
                                        <Table
                                            rowKey="_id"
                                            size="small"
                                            dataSource={materialCounts || []}
                                            columns={columns}
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            )
                        )}
                    </Col>
                ) : null}

                {planogramAiResult?.length ? (
                    <Col sm={12} lg={8}>
                        {planogramAiResult?.map((item, i) => (
                            <div
                                key={i + 1}
                                style={{
                                    backgroundColor: 'white',
                                    padding: '5px',
                                    borderRadius: '5px',
                                    marginTop: '5px',
                                }}
                            >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div className="ai-info">
                                        <p>
                                            <span>Planogram : </span> Planogram {item.planogram}
                                        </p>
                                        <p>
                                            <span>Owner Name : </span> {item.owner}
                                        </p>
                                        <p>
                                            <span>Detected Owner : </span> {item.detectedOwner}
                                        </p>
                                    </div>
                                    <div>
                                        <Image
                                            src={item.url}
                                            width={50}
                                            style={{ borderRadius: '5px' }}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <p style={{ fontWeight: '600', margin: '6px 0 2px 0' }}>
                                        Blanks:
                                    </p>
                                    <Table
                                        rowKey="_id"
                                        size="small"
                                        dataSource={item.materialCounts || []}
                                        columns={columns}
                                        pagination={false}
                                    />
                                </div>
                            </div>
                        ))}
                    </Col>
                ) : null}
            </Row>
            <div>
                <Divider style={{ color: 'white', borderColor: '#fff' }}>SOV Calculation</Divider>
                <Row gutter={[10, 10]}>
                    <Col sm={24} md={12}>
                        <p style={{ fontWeight: '600', margin: '6px 0 2px 0', color: '#fff' }}>
                            Surface Area:
                        </p>
                        <Table
                            rowKey="_id"
                            size="small"
                            dataSource={
                                Object.entries(finalResult).map(([k, v]) => ({
                                    _id: k,
                                    name: k,
                                    size: `${v.size.toFixed(2)}sq ft`,
                                    percent: `${Math.round(v.sizePercent)}%`,
                                })) || []
                            }
                            columns={byAreaColumns}
                            pagination={false}
                        />
                    </Col>
                    <Col sm={24} md={12}>
                        <p style={{ fontWeight: '600', margin: '6px 0 2px 0', color: '#fff' }}>
                            By Count:
                        </p>
                        <Table
                            rowKey="_id"
                            size="small"
                            dataSource={
                                Object.entries(finalResult).map(([k, v]) => ({
                                    _id: k,
                                    name: k,
                                    count: v.count,
                                    percent: `${Math.round(v.countPercent)}%`,
                                })) || []
                            }
                            columns={byCountColumns}
                            pagination={false}
                        />
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default AiResult;
