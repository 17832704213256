/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import moment from 'moment';
import * as XLSX from 'xlsx';

export default async function getPmmAiReport(data, token, onCompleted) {
    let res = [];
    try {
        onCompleted(false);
        const { size: _s } = await (
            await fetch('https://api.hedigital.app/api/reports/getMerchandisingPresencePMMAISize', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(data),
            })
        ).json();

        let page = 0;
        while (++page) {
            const json = (
                await (
                    await fetch(
                        `https://api.hedigital.app/api/reports/getMerchandisingPresencePMMAIReport?page=${page}&limit=10000`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(data),
                        }
                    )
                ).json()
            ).map((element) => element.row);
            if (!json.length) break;
            res = res.concat(json);
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(
            [
                [
                    'Date',
                    'Time',
                    'Region',
                    'Area',
                    'Distribution House',
                    'Territory',
                    'Point',
                    'Route/Section',
                    'CM Code',
                    'CM Name',
                    'MS Name',
                    'MS Code',
                    'Outlet Code',
                    'Outlet Name',
                    'Owner Name',
                    'Outlet Contact No',
                    'Channel',
                    'Sub Channel',
                    'TPG',
                    'Cluster',
                    'Cluster Type',
                    'Geo',
                    'Planogram',
                    'Partner Code',
                    'DCC Scope',
                    'Last Edit By',
                    ...new Array(_s)
                        .fill(null)
                        .flatMap((_, i) => [`CM Input PMM-${i + 1}(Row*Column)`, null, null]),
                    'BATB PMM Presence',
                    'Competition PMM',
                    'BLANKS',
                    ...new Array(47).fill(null),
                ],
                [
                    ...new Array(26).fill(null),
                    ...new Array(_s).fill(null).flatMap((_, i) => [null, null, null]),
                    ...new Array(2).fill(null),
                    'BATB',
                    ...new Array(24).fill(null),
                    'JTI',
                    ...new Array(11).fill(null),
                    'PMI',
                    ...new Array(2).fill(null),
                    'AKTC',
                    ...new Array(7).fill(null),
                ],
                [
                    ...new Array(26).fill(null),
                    ...new Array(_s).fill(null).flatMap((_, i) => ['Owner', 'Row', 'Column']),
                    ...new Array(2).fill(null),
                    ...[
                        'Alchemy 7mg',
                        'Alchemy Mix1',
                        'BnH BG',
                        'BnH FF',
                        'BnH PT',
                        'BnH SW',
                        'Derby',
                        'Derby Style',
                        'Flag',
                        'Gold Leaf LEPP',
                        'Hollywood',
                        'JPFF',
                        'JPSPCL',
                        'JPSW',
                        'LS CC',
                        'LS FF',
                        'LS SB',
                        'Pilot',
                        'Royals LEPP',
                        'RoyalsGold',
                        'RoyalsLS',
                        'RoyalsNext',
                        'Star',
                        'Star Next',
                        'STAR SW',
                    ],
                    ...[
                        'Camel Connect Blue Burst',
                        'Camel Connect Dark Blue',
                        'Camel Connect Purple Burst',
                        'K2',
                        'LD Blue',
                        'LD Red',
                        'LEVEL UP',
                        'NAVY option',
                        'Navy Special Filter',
                        'Rally Bolt',
                        'Sheikh FF',
                        'Sheikh Smooth',
                    ],
                    ...['Marlboro Advanced', 'Marlboro Gold', 'Marlboro Red'],
                    ...[
                        'Marise',
                        'Marise Black Diamond',
                        'Rally Verve Red',
                        'Rally Ice Blue',
                        'Rexon',
                        'SUN Moon American Blend',
                        'SUN Moon Brazillian Blend',
                        'SUN Moon Turkish Blend',
                    ],
                ],
            ].concat(res)
        );

        ws['!merges'] = [
            ...new Array(26).fill(null).map((_, i) => ({ s: { c: i, r: 0 }, e: { c: i, r: 2 } })),
            ...new Array(_s).fill(null).map((_, i) => ({
                s: { c: 26 + i * 3, r: 0 },
                e: { c: 26 + (i + 1) * 3 - 1, r: 1 },
            })),
            ...new Array(3).fill(null).map((_, i) => ({
                s: { c: 26 + _s * 3 + i, r: i === 2 ? 0 : 0 },
                e: {
                    c: i === 2 ? 26 + _s * 3 + i + 47 : 26 + _s * 3 + i,
                    r: i === 2 ? 0 : 2,
                },
            })),
            ...new Array(_s * 3)
                .fill(null)
                .map((_, i) => ({ s: { c: 26 + i, r: 2 }, e: { c: 26 + i, r: 2 } })),
            ...[24, 11, 2, 7].reduce(
                ([an, aa], n) => {
                    aa.push({
                        s: { c: 26 + _s * 3 + 2 + an, r: 1 },
                        e: { c: 26 + _s * 3 + 2 + an + n, r: 1 },
                    });
                    return [an + n + 1, aa];
                },
                [0, []]
            )[1],
            ...new Array(48).fill(null).map((_, i) => ({
                s: { c: 26 + _s * 3 + 2 + i, r: 2 },
                e: { c: 26 + _s * 3 + 2 + i, r: 2 },
            })),
        ];

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, `Pmm-Ai-Report_${moment().format('D-MMMM-YYYY-h-mm-A')}.xlsx`);
    } catch (error) {
        console.error(error);
    } finally {
        onCompleted(true);
        res = null;
    }
}
