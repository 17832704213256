/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import moment from 'moment';
import * as XLSX from 'xlsx';

export default async function getGeolocationReport(data, token, onCompleted) {
    let res = [];
    try {
        onCompleted(false);

        let page = 0;
        while (++page) {
            const json = (
                await (
                    await fetch(
                        `https://api.hedigital.app/api/reports/getGeolocationReport?page=${page}&limit=10000`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(data),
                        }
                    )
                ).json()
            ).map((element) => element.row);
            if (!json.length) break;
            res = res.concat(json);
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(
            [
                [
                    'Date',
                    'Time',
                    'Region',
                    'Area',
                    'Distribution House',
                    'Territory',
                    'Point',
                    'Route/Section',
                    'CM Code',
                    'CM Name',
                    'MS Name',
                    'MS Code',
                    'Outlet Code',
                    'Outlet Name',
                    'Owner Name',
                    'Outlet Contact No',
                    'Channel',
                    'Sub Channel',
                    'TPG',
                    'Cluster',
                    'Cluster Type',
                    'Geo',
                    'PMM',
                    'Partner Code',
                    'DCC Scope',
                    'Last Edit By',
                    'Geo location Match?',
                    'Latitude',
                    'Longitude',
                    'Duration',
                    'Distance',
                    'Start Time',
                    'End Time',
                ],
            ].concat(res)
        );

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, `Geolocation-Report_${moment().format('D-MMMM-YYYY-h-mm-A')}.xlsx`);
    } catch (error) {
        console.error(error);
    } finally {
        onCompleted(true);
        res = null;
    }
}
