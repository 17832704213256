/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    startDate: '',
    endDate: '',
    reportType: '',
    report: '',
    globalLoading: false,
};

const downloadReportFilterSlice = createSlice({
    name: 'downloadReportFilterSlice',
    initialState,
    reducers: {
        downloadStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        downloadEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        reportType: (state, action) => {
            state.reportType = action.payload;
        },
        setReport: (state, action) => {
            state.report = action.payload;
        },
        setGlobalLoading: (state, action) => {
            state.globalLoading = action.payload;
        },
        resetDownloadFilter: (state, action) => {
            state.startDate = '';
            state.endDate = '';
            state.report = '';
            state.reportType = '';
        },
    },
});

export default downloadReportFilterSlice.reducer;
export const {
    downloadStartDate,
    downloadEndDate,
    reportType,
    setReport,
    setGlobalLoading,
    resetDownloadFilter,
} = downloadReportFilterSlice.actions;
