/* eslint-disable react/no-array-index-key */
import { Col, Row } from 'antd';
import React from 'react';

function PresenceInformation({ info }) {
    return (
        <div>
            {[...info.pmm, ...info.posm, ...info.planogram].length ? (
                <Row style={{ marginTop: '10px' }} gutter={[5, 5]}>
                    {info.pmm.length ? (
                        <Col lg={8} sm={24}>
                            <div
                                style={{
                                    borderRadius: '5px',
                                    overflow: 'hidden',
                                    border: '1px solid #fde795',
                                }}
                            >
                                <h3
                                    style={{
                                        color: '#000000',
                                        backgroundColor: '#fde795',
                                        padding: '5px',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    PMM Information
                                </h3>
                                <div style={{ padding: '0 2px 2px 2px' }}>
                                    {info.pmm?.map((item, i) => (
                                        <div key={item.pmmName + i} className="single-item">
                                            <p className="info-item">
                                                <span>PMM: </span> {i + 1}
                                            </p>
                                            <p>
                                                <span>PMM Owner: </span> {item.pmmOwner}
                                            </p>
                                            <p>
                                                <span>PMM Name: </span> {item.pmmName}
                                            </p>
                                            <p>
                                                <span>PMM Type: </span> {item.pmmType}
                                            </p>
                                            {item.pmmOwner === 'VBP SPONSOR' ? (
                                                <>
                                                    <p>
                                                        <span>PMM Row: </span>{' '}
                                                        {item.rowAndColumn.Row}
                                                    </p>
                                                    <p>
                                                        <span>PMM Column: </span>{' '}
                                                        {item.rowAndColumn.Column}
                                                    </p>
                                                    <p>
                                                        <span>Have Ralling: </span>{' '}
                                                        {item.haveRailing}
                                                    </p>
                                                </>
                                            ) : null}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    ) : null}

                    {info.posm.length ? (
                        <Col lg={8} sm={24}>
                            <div
                                style={{
                                    borderRadius: '5px',
                                    overflow: 'hidden',
                                    border: '1px solid #fde795',
                                }}
                            >
                                <h3
                                    style={{
                                        color: '#000000',
                                        backgroundColor: '#fde795',
                                        padding: '5px',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    POSM Information
                                </h3>
                                <div style={{ padding: '0 2px 2px 2px' }}>
                                    {info.posm?.map((item, i) => (
                                        <div key={item.posmType + i} className="single-item">
                                            <p className="info-item">
                                                <span>POSM: </span> {i + 1}
                                            </p>
                                            <p>
                                                <span>POSM Owner: </span> {item.posmOwner}
                                            </p>
                                            <p>
                                                <span>POSM Type: </span> {item.posmType}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    ) : null}

                    {info.planogram.length ? (
                        <Col lg={8} sm={24}>
                            <div
                                style={{
                                    borderRadius: '5px',
                                    overflow: 'hidden',
                                    border: '1px solid #fde795',
                                }}
                            >
                                <h3
                                    style={{
                                        color: '#000000',
                                        backgroundColor: '#fde795',
                                        padding: '5px',
                                        width: '100%',
                                        textAlign: 'center',
                                    }}
                                >
                                    Planogram Information
                                </h3>
                                <div style={{ padding: '0 2px 2px 2px' }}>
                                    {info.planogram?.map((item, i) => (
                                        <div key={item.planogramOwner + i} className="single-item">
                                            <p className="info-item">
                                                <span>Planogram: </span> {i + 1}
                                            </p>
                                            <p>
                                                <span>Planogram Owner: </span> {item.planogramOwner}
                                            </p>
                                            <p>
                                                <span>Planogram Row: </span> {item.rowAndColumn.Row}
                                            </p>
                                            <p>
                                                <span>Planogram Column: </span>{' '}
                                                {item.rowAndColumn.Column}
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    ) : null}
                </Row>
            ) : (
                <p style={{ color: '#fff', marginTop: '10px' }}>No data found!</p>
            )}
        </div>
    );
}

export default PresenceInformation;
