import { Col, DatePicker } from 'antd';
import React from 'react';

function DateRange({ startFn, endFn }) {
    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    size="large"
                    className="filter-calender"
                    placeholder="Start Date"
                    onChange={startFn}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <DatePicker
                    className="filter-calender"
                    size="large"
                    placeholder="End Date"
                    onChange={endFn}
                />
            </Col>
        </>
    );
}

export default DateRange;
