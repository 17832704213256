/* eslint-disable prettier/prettier */
import { FilterOutlined } from "@ant-design/icons";
import { Button, Col, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setChannel, setPmmStatus, setSelectedBy, setSubChannel } from "../../redux/features/filter/pmmMaintenanceFilterSlice";

function PmmMaintenanceFilter({ surveyDataFetch }) {
  const dispatch = useDispatch();

  const {
    user: { employeeLevel },
  } = useSelector(state => state.auth);

  return (
    <>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Select
          allowClear
          showArrow
          mode="multiple"
          placeholder="PMM Status"
          size="large"
          style={{
            width: "100%",
          }}
          onChange={e => dispatch(setPmmStatus(e))}
          options={[
            {
              value: "Maintenance",
              label: "Maintenance",
            },
            {
              value: "Critical for Visibility",
              label: "Critical for Visibility",
            },
            {
              value: "All",
              label: "All",
            },
          ]}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Select
          allowClear
          showArrow
          mode="multiple"
          placeholder="Channel"
          size="large"
          style={{
            width: "100%",
          }}
          onChange={e => dispatch(setChannel(e))}
          options={[
            {
              value: "C&C Channel",
              label: "C&C Channel",
            },
            {
              value: "GT Channel",
              label: "GT Channel",
            },
            {
              value: "TLP Channel",
              label: "TLP Channel",
            },
          ]}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Select
          allowClear
          showArrow
          mode="multiple"
          placeholder="Sub Channel"
          size="large"
          style={{
            width: "100%",
          }}
          onChange={e => dispatch(setSubChannel(e))}
          options={[
            {
              value: "BCC",
              label: "BCC",
            },
            {
              value: "GT",
              label: "GT",
            },
            {
              value: "MVP",
              label: "MVP",
            },
            {
              value: "P",
              label: "P",
            },
            {
              value: "RCC",
              label: "RCC",
            },
            {
              value: "VP",
              label: "VP",
            },
          ]}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Select
          allowClear
          showArrow
          mode="multiple"
          placeholder="Selected By"
          size="large"
          style={{
            width: "100%",
          }}
          onChange={e => dispatch(setSelectedBy(e))}
          options={[
            {
              value: "TO",
              label: "TO",
            },
            {
              value: "AM",
              label: "AM",
            },
          ]}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Button
          size="large"
          className="filter-btn"
          icon={<FilterOutlined />}
          type="primary"
          onClick={() => surveyDataFetch(1, 10, "cleanShowResultOnPage")}
        >
          Filter
        </Button>
      </Col>
    </>
  );
}

export default PmmMaintenanceFilter;
