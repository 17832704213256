/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    startDate: '',
    endDate: '',
};

const dashboardFilterSlice = createSlice({
    name: 'dashboardFilterSlice',
    initialState,
    reducers: {
        dashboardStartDate: (state, action) => {
            state.startDate = action.payload;
        },
        dashboardEndDate: (state, action) => {
            state.endDate = action.payload;
        },
        resetDashboardFilter: (state, action) => {
            state.startDate = '';
            state.endDate = '';
        },
    },
});

export default dashboardFilterSlice.reducer;
export const { dashboardStartDate, dashboardEndDate, resetDashboardFilter } =
    dashboardFilterSlice.actions;
