/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */
import { PlusOutlined } from '@ant-design/icons';
import { Input, Tag, Tooltip } from 'antd';
import React, { useEffect, useRef, useState } from 'react';

function TagComponent({ data, setData, idx }) {
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState('');
    const inputRef = useRef(null);
    const editInputRef = useRef(null);
    useEffect(() => {
        if (inputVisible) {
            inputRef.current?.focus();
        }
    }, [inputVisible]);
    useEffect(() => {
        editInputRef.current?.focus();
    }, [inputValue]);
    const handleClose = (removedTag) => {
        // console.log(data[idx].routeSection);
        const newTags = data[idx].routeSection.filter((tag) => tag !== removedTag);
        // console.log(newTags);(prev[idx].routeSection = newTags)
        setData((prev) =>
            prev.map((rData, i) => (i === idx ? { ...rData, routeSection: newTags } : { ...rData }))
        );
    };
    const showInput = () => {
        setInputVisible(true);
    };
    const handleInputChange = (e) => {
        setInputValue(e.target.value.trim());
    };
    const handleInputConfirm = () => {
        if (inputValue && data[idx].routeSection.indexOf(inputValue) === -1) {
            // setData((prev) => (prev[idx].routeSection = [...data[idx].routeSection, inputValue]));
            setData((prev) =>
                prev?.map((rData, i) =>
                    i === idx
                        ? { ...rData, routeSection: [...rData.routeSection, inputValue] }
                        : { ...rData }
                )
            );
        }
        setInputVisible(false);
        setInputValue('');
    };
    const handleEditInputChange = (e) => {
        setEditInputValue(e.target.value.trim());
    };
    const handleEditInputConfirm = () => {
        const newTags = [...data[idx].routeSection];
        newTags[editInputIndex] = editInputValue;
        setData((prev) =>
            prev.map((rData, i) =>
                i === idx ? { ...rData, routeSection: [...newTags] } : { ...rData }
            )
        );
        setEditInputIndex(-1);
        setInputValue('');
    };
    return (
        <>
            {data[idx]?.routeSection?.map((tag, index) => {
                if (editInputIndex === index) {
                    return (
                        <Input
                            ref={editInputRef}
                            key={tag}
                            size="small"
                            className="tag-input"
                            value={editInputValue}
                            onChange={handleEditInputChange}
                            onBlur={handleEditInputConfirm}
                            onPressEnter={handleEditInputConfirm}
                        />
                    );
                }
                const isLongTag = tag.length > 20;
                const tagElem = (
                    <Tag className="edit-tag" key={tag} closable onClose={() => handleClose(tag)}>
                        <span
                            onDoubleClick={(e) => {
                                if (index !== 0) {
                                    setEditInputIndex(index);
                                    setEditInputValue(tag);
                                    e.preventDefault();
                                }
                            }}
                        >
                            {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                        </span>
                    </Tag>
                );
                return isLongTag ? (
                    <Tooltip title={tag} key={tag}>
                        {tagElem}
                    </Tooltip>
                ) : (
                    tagElem
                );
            })}
            {inputVisible && (
                <Input
                    ref={inputRef}
                    type="text"
                    size="small"
                    className="tag-input"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                />
            )}
            {!inputVisible && (
                <Tag className="site-tag-plus" onClick={showInput}>
                    <PlusOutlined /> New R/S
                </Tag>
            )}
        </>
    );
}
export default TagComponent;
