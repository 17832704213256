/* eslint-disable no-shadow */
/* eslint-disable prettier/prettier */
/* eslint-disable import/no-extraneous-dependencies */
import {
    CloudDownloadOutlined, HomeOutlined,
    LogoutOutlined,
    MergeCellsOutlined,
    PieChartOutlined, TeamOutlined, UserOutlined
} from '@ant-design/icons';
import { Button, Layout, Menu, Popover, theme } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/logo.svg';
import { userLoggedOut } from '../../redux/features/auth/authSlice';
import IdleModal from '../../ui/IdleSession';

const { Header, Content, Footer, Sider } = Layout;


function Dashboard( {children} ) {
    // navbar collapsed hook
    const [collapsed, setCollapsed] = useState(false);

    const { user } = useSelector((state) => state.auth);
    const { globalLoading } = useSelector((state) => state.downloadFilter);

    const dispatch = useDispatch();

    // navigate hook
    const navigate = useNavigate();
    const { pathname } = useLocation();

    // active item detection
    function getActivePath (path) {
        let activeKey;
        switch (path) {
            case '/':
                activeKey = '1'
                break;
            case '/survey':
                activeKey = '2'
                break;
            case '/pmm-maintenance':
                activeKey = '3'
                break;
            case '/cm-mapping':
                activeKey = '4';
                break;
            case '/download-report':
                activeKey = '5';
                break;
            default:
                break;
        }
        return activeKey;
    }

    function getItem(label, key, link, icon, children) {
        return {
            key,
            icon,
            children,
            label,
            onClick: () => navigate(link),
        };
    }
    

    // menu item
    const items = [
        getItem('Dashboard', '1', '/', <HomeOutlined />),
        getItem('PMM Maintenance', '3', '/pmm-maintenance', <MergeCellsOutlined />),
    ];

    if (user?.employeeLevel === 'Admin' || user?.employeeLevel === 'Super Admin') {
        items.push(getItem('CM Mapping', '4', '/cm-mapping', <TeamOutlined />))
    }
    if (user?.roles === 'HEDML') {
        items.push(getItem('Survey', '2', '/survey', <PieChartOutlined />))
    }
    items.push(getItem('Download Report', '5', '/download-report', <CloudDownloadOutlined />))

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const logOut = () => {
        sessionStorage.removeItem('auth');
        dispatch(userLoggedOut());
        navigate('/login')
    }

    // user prover information
    const content = (
        <div>
            <Button type="primary" danger onClick={logOut}>
                <LogoutOutlined /> Logout
            </Button>
        </div>
    );

    return (
        <Layout
            style={{
                minHeight: '100vh',
            }}
            className={`${globalLoading ? 'global-loading' : ''}`}
        >
            <Sider
                style={{
                    background: colorBgContainer.dark,
                }}
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
            >
                <div
                    style={{
                        padding: '5px',
                        textAlign: 'center',
                        transition: 'all 0.05s ease in out',
                    }}
                >
                    <Link to="/">
                        <img
                            src={logo}
                            alt="Logo"
                            style={{
                                width: collapsed ? '100%' : '80%',
                                border: '1px solid gray',
                                borderRadius: '3px',
                            }}
                        />
                    </Link>
                </div>
                <Menu defaultSelectedKeys={['1']} selectedKeys={[getActivePath(pathname)]} mode="inline" items={items} theme="dark" />
            </Sider>
            <Layout className="site-layout">
                <Header
                    style={{
                        padding: '0 50px 0 0',
                        background: '#faad14',
                    }}
                >
                    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                        <div className="" style={{textAlign: 'center', width: '100%'}}>
                            <p className="system-title">NMSM PORTAL</p>
                        </div>
                        <div className="user">
                            <Popover content={content}>
                                <UserOutlined style={{ fontSize: '25px', color: '#fff' }}  />
                            </Popover>
                        </div>
                    </div>
                </Header>
                <Content
                    style={{
                        margin: '0 16px',
                    }}
                >
                    <IdleModal timeout={1200} autoCloseTime={300} />
                    {
                        children 
                    }
                </Content>
                <Footer
                    style={{
                        textAlign: 'center',
                    }}
                >
                    &copy;HawkEyes Digital Monitoring Ltd. {new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
}
export default Dashboard;
