/* eslint-disable prettier/prettier */
import { FilterOutlined } from '@ant-design/icons';
import { Col } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    dashboardEndDate,
    dashboardStartDate
} from '../../redux/features/filter/dashboardFilterSlice';
import DateRange from './DateRange';
import FilterButton from './FilterButton';

function DashboardFilter({surveyDataFetch}) {
    const dispatch = useDispatch();

    const startDateFn = (_, dateString) => {
        dispatch(dashboardStartDate(dateString));
    };

    const endDateFn = (_, dateString) => {
        dispatch(dashboardEndDate(dateString));
    };
    return (
        <>
            <DateRange startFn={startDateFn} endFn={endDateFn} />
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <FilterButton fn={surveyDataFetch} icon={<FilterOutlined />} title="Filter" />
            </Col>
        </>
    );
}

export default DashboardFilter;
