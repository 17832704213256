/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import moment from 'moment';
import * as XLSX from 'xlsx';

export default async function getMerchandisingOppurtinityPlanogramReport(data, token, onCompleted) {
    let res = [];
    try {
        onCompleted(false);
        const { size } = await (
            await fetch(
                'https://api.hedigital.app/api/reports/getMerchandisingOppurtinityPlanogramSize',
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(data),
                }
            )
        ).json();

        let page = 0;
        while (++page) {
            const json = (
                await (
                    await fetch(
                        `https://api.hedigital.app/api/reports/getMerchandisingOppurtinityPlanogramReport?page=${page}&limit=10000`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(data),
                        }
                    )
                ).json()
            ).map((element) => element.row);
            if (!json.length) break;
            res = res.concat(json);
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(
            [
                [
                    'Date',
                    'Time',
                    'Region',
                    'Area',
                    'Distribution House',
                    'Territory',
                    'Point',
                    'Route/Section',
                    'CM Code',
                    'CM Name',
                    'MS Name',
                    'MS Code',
                    'Outlet Code',
                    'Outlet Name',
                    'Owner Name',
                    'Outlet Contact No',
                    'Channel',
                    'Sub Channel',
                    'TPG',
                    'Cluster',
                    'Cluster Type',
                    'Geo',
                    'Planogram',
                    'Partner Code',
                    'DCC Scope',
                    'Last Edit By',
                    ...new Array(size)
                        .fill('')
                        .flatMap((_, index) => [
                            `Planogram-${index + 1}`,
                            ...new Array(2).fill(null),
                        ]),
                ],
                [
                    ...new Array(26).fill(null),
                    ...new Array(size).fill('').flatMap(() => ['Placement', 'Row', 'Column']),
                ],
                [
                    ...new Array(26).fill(null),
                    ...new Array(size).fill('').flatMap(() => [null, null, null]),
                ],
            ].concat(res)
        );

        ws['!merges'] = [
            ...new Array(26).fill('').map((_, i) => ({ s: { c: i, r: 0 }, e: { c: i, r: 2 } })),
            ...new Array(size * 3)
                .fill('')
                .map((_, i) => i)
                .filter((n) => n % 3 === 0)
                .map((n) => ({
                    s: { c: 26 + n, r: 0 },
                    e: { c: 26 + (3 - 1) + n, r: 0 },
                })),
            ...new Array(size * 3)
                .fill('')
                .map((_, i) => ({ s: { c: 26 + i, r: 1 }, e: { c: 26 + i, r: 2 } })),
        ];

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(
            wb,
            `MerchandisingOppurtinity-Planogram-Report_${moment().format(
                'D-MMMM-YYYY-h-mm-A'
            )}.xlsx`
        );
    } catch (error) {
        console.error(error);
    } finally {
        onCompleted(true);
        res = null;
    }
}
