/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import moment from 'moment';
import * as XLSX from 'xlsx';

export default async function getMCEvaluationReport(data, token, onCompleted) {
    let res = [];
    try {
        onCompleted(false);

        let page = 0;
        while (++page) {
            const json = (
                await (
                    await fetch(
                        `https://api.hedigital.app/api/reports/getMCValidationReport?page=${page}&limit=10000`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(data),
                        }
                    )
                ).json()
            ).map((element) => element.row);
            if (!json.length) break;
            res = res.concat(json);
        }

        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(
            [
                [
                    'Evaluation Date',
                    'CM Surveyed Timestamp',
                    'Region',
                    'Area',
                    'Distribution House',
                    'Territory',
                    'Point',
                    'Route/Section',
                    'CM Code',
                    'CM Name',
                    'MS Code',
                    'MS Name',
                    'MC Name',
                    'Outlet Code',
                    'Outlet Name',
                    'Owner Name',
                    'Outlet Contact No',
                    'Channel',
                    'Sub Channel',
                    'TPG',
                    'Cluster',
                    'Cluster Type',
                    'Geo',
                    'PMM',
                    'Partner Code',
                    'DCC Scope',
                    'Latitude',
                    'Longitude',
                    'Start Time',
                    'End Time',
                    'Duration',
                    'Call Type',
                    'Evaluation Type',
                    'Execution Evaluation',
                    ...new Array(3).fill(null),
                ],
                [
                    ...new Array(33).fill(null),
                    'Is CM survey the right outlet?',
                    'Outlet survey presence info. are correct?',
                    'Outlet survey opportunity info. are correct?',
                    'PMM maintenance info. are correct?',
                ],
            ].concat(res)
        );

        ws['!merges'] = [
            ...new Array(33).fill('').map((_, i) => ({ s: { r: 0, c: i }, e: { r: 1, c: i } })),
            { s: { r: 0, c: 33 }, e: { r: 0, c: 36 } },
            ...new Array(4)
                .fill('')
                .map((_, i) => ({ s: { r: 1, c: 33 + i }, e: { r: 1, c: 33 + i } })),
        ];

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, `MC-Evaluation-Report_${moment().format('D-MMMM-YYYY-h-mm-A')}.xlsx`);
    } catch (error) {
        console.error(error);
    } finally {
        onCompleted(true);
        res = null;
    }
}
