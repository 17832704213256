/* eslint-disable react/no-array-index-key */
import { Col, Row } from 'antd';
import React from 'react';

function MaintenanceInformation({ info }) {
    return (
        <div style={{ marginTop: '10px' }}>
            {info.length ? (
                <div style={{ padding: '0 2px 2px 2px' }}>
                    <Row gutter={[5, 5]}>
                        {info?.map((item, i) => (
                            <Col lg={8} sm={24} key={item.pmmType + i}>
                                <div
                                    key={item.pmmType + i}
                                    className="single-item"
                                    style={{ border: '1px solid #DEDBDB' }}
                                >
                                    <p className="info-item">
                                        <span>Maintenance: </span> {i + 1}
                                    </p>
                                    <p>
                                        <span>PMM Type: </span> {item.pmmType}
                                    </p>
                                    <p>
                                        <span>Maintenance Type: </span>{' '}
                                        {item.maintenanceType?.map((m, idx) => (
                                            <span style={{ fontWeight: 'normal' }} key={m + idx}>
                                                {m},{' '}
                                            </span>
                                        ))}
                                    </p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
            ) : (
                <p style={{ color: '#fff' }}>No data found!</p>
            )}
        </div>
    );
}

export default MaintenanceInformation;
