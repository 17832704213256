import { configureStore } from '@reduxjs/toolkit';
import authSlice from '../features/auth/authSlice';
import cmMappingFilterSlice from '../features/filter/cmMappingFilterSlice';
import dashboardFilterSlice from '../features/filter/dashboardFilterSlice';
import downloadReportFilterSlice from '../features/filter/downloadReportFilterSlice';
import mmFilterSlice from '../features/filter/mmFilterSlice';
import pmmMaintenanceFilterSlice from '../features/filter/pmmMaintenanceFilterSlice';
import surveyFilterSlice from '../features/filter/surveyFilterSlice';

const store = configureStore({
    reducer: {
        // [apiSlice.reducerPath]: apiSlice.reducer,
        auth: authSlice,
        mmFilter: mmFilterSlice,
        dashboardFilter: dashboardFilterSlice,
        surveyFilter: surveyFilterSlice,
        pmmMaintenanceFilter: pmmMaintenanceFilterSlice,
        cmMappingFilter: cmMappingFilterSlice,
        downloadFilter: downloadReportFilterSlice,
    },
    devTools: process.env.NODE_ENV !== 'production',
    // middleware: (getDefaultMiddlewares) => getDefaultMiddlewares().concat(apiSlice.middleware),
});

export default store;
