/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
import { EnvironmentOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Image, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import downImg from '../../assets/down.png';
import AiResult from '../Survey/AiResult';
import MaintenanceInformation from '../Survey/MaintenanceInformation';
import MapModal from '../Survey/MapModal';
import OpportunityInformation from '../Survey/OpportunityInformation';
import PresenceInformation from '../Survey/PresenceInformation';

function Item({ data, checkList, setCheckList }) {
    const { executionInfo, merchandisingOpportunity, merchandisingPresence, aiRun, _id } = data;

    // execution information desctructured
    const {
        point,
        cmName,
        outletName,
        outletCode,
        supervisorCode,
        cmCode,
        clusterName,
        contactNumber,
        routeSection,
        locationMatch,
        distance,
        channel,
        subChannel,
        pmmStatus,
        status,
        duration,
        evaluationType,
        approved,
        selected,
        rejected,
    } = executionInfo;

    const {
        user: { level },
    } = useSelector((state) => state.auth);

    // image generate form survey information
    const generateImage = [
        ...merchandisingPresence.pmm,
        ...merchandisingPresence.posm,
        ...merchandisingPresence.planogram,
        ...merchandisingOpportunity.pmm,
        ...merchandisingOpportunity.posm,
        ...merchandisingOpportunity.planogram,
    ]
        ?.map((item) => item?.imageURLs?.map((img) => ({ title: img?.name, url: img?.url })))
        .flat();
    generateImage.unshift({
        title: executionInfo.image.name,
        url: executionInfo.image.url,
    });

    // expend state
    const [isExtend, setIsExtend] = useState('');

    // modal state and function
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // ex location modal
    const [isExModalOpen, setExIsModalOpen] = useState(false);
    const showExModal = () => {
        setExIsModalOpen(true);
    };
    const handleExOk = () => {
        setExIsModalOpen(false);
    };
    const handleExCancel = () => {
        setExIsModalOpen(false);
    };

    const renderInformationData = (str) => {
        let info;
        if (str === 'presence') {
            info = <PresenceInformation info={merchandisingPresence} />;
        } else if (str === 'opportunity') {
            info = <OpportunityInformation info={merchandisingOpportunity} />;
        } else if (str === 'maintenance') {
            info = <MaintenanceInformation info={merchandisingPresence?.pmmMaintenance} />;
        } else if (str === 'aiResult') {
            info = <AiResult info={merchandisingPresence} />;
        } else {
            info = null;
        }
        return info;
    };

    const onChange = (e) => {
        setCheckList((prev) =>
            e.target.checked ? [...prev, _id] : prev.filter((id) => id !== _id)
        );
    };

    function isDisabled(lev, approve, select, rej) {
        if (lev === 'TO') {
            return JSON.stringify(select) === 'true';
        }
        if (lev === 'AM') {
            if (JSON.stringify(approve) === 'true') {
                return true;
            }
            if (JSON.stringify(rej) === 'true') {
                return true;
            }
            return false;
        }
    }
    return (
        <>
            <Modal
                footer={false}
                title="Location in google Map"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width="850px"
                bodyStyle={{ padding: 0 }}
            >
                <MapModal
                    lat={executionInfo?.newLocation?.latitude}
                    lon={executionInfo?.newLocation?.longitude}
                />
            </Modal>

            <div
                className="maintenance-item"
                style={
                    checkList?.includes(_id) || isDisabled(level, approved, selected, rejected)
                        ? {
                              background: '#f1f5ff',
                          }
                        : {}
                }
            >
                <Row gap={[5, 5]} justify="space-between">
                    {level === 'TO' || level === 'AM' ? (
                        <Col lg={1} md={1} sm={24}>
                            <Checkbox
                                disabled={isDisabled(level, approved, selected, rejected)}
                                checked={
                                    checkList?.includes(_id) ||
                                    isDisabled(level, approved, selected, rejected)
                                }
                                onChange={onChange}
                            />
                        </Col>
                    ) : null}
                    <Col lg={6} md={12} sm={24} className="maintenance-item-p">
                        <p>
                            <span className="bold-font">Point: </span>
                            {point}
                        </p>
                        <p>
                            <span className="bold-font">CM Name: </span>
                            {cmName}
                        </p>
                        <p>
                            <span className="bold-font">Outlet Name: </span>
                            {outletName}
                        </p>

                        <p>
                            <span className="bold-font">Outlet Code: </span>
                            {outletCode}
                        </p>
                    </Col>
                    <Col lg={6} md={12} sm={24} className="left-border maintenance-item-p">
                        <p>
                            <span className="bold-font">Cluster: </span>
                            {clusterName}
                        </p>
                        <p>
                            <span className="bold-font">Mobile: </span>
                            {contactNumber}
                        </p>
                        <p>
                            <span className="bold-font">Route: </span>
                            {routeSection}
                        </p>
                    </Col>
                    <Col lg={5} md={11} sm={24} className="left-border maintenance-item-p">
                        <p>
                            <span className="bold-font">PMM Status: </span>
                            {pmmStatus.filter((x) => x !== 'All').join(', ') || ''}
                        </p>
                        <p>
                            <span className="bold-font">Selected By TO: </span>
                            {selected ? 'Yes' : ''}
                        </p>
                        <p>
                            <span className="bold-font">Approved By AM: </span>
                            {(JSON.stringify(approved) === 'true' && 'Yes') ||
                                (JSON.stringify(rejected) === 'true' && 'No')}
                        </p>
                    </Col>
                    <Col lg={6} md={12} sm={24} className="left-border">
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {/* <Button type="primary" danger style={{ marginRight: '5px' }}>
                                Evaluate
                            </Button> */}
                            <Button
                                ghost
                                type="primary"
                                title="New Location"
                                onClick={() => setIsModalOpen((prev) => !prev)}
                            >
                                <EnvironmentOutlined />
                            </Button>
                        </div>
                        <div className="survey-last-section">
                            <Image.PreviewGroup
                                preview={{
                                    countRender: (current) =>
                                        generateImage[current - 1]?.title || 'null',
                                }}
                            >
                                <Row gutter={[2, 2]}>
                                    {generateImage?.map((image, i) => (
                                        <Col key={image.url + i}>
                                            <Image width={45} src={image.url} />
                                        </Col>
                                    ))}
                                </Row>
                            </Image.PreviewGroup>
                        </div>
                    </Col>
                    <div className="btn-container">
                        <button
                            onClick={() =>
                                setIsExtend((prev) => (prev === 'presence' ? '' : 'presence'))
                            }
                            type="button"
                            className="presence-btn survey-btn"
                        >
                            Presence{' '}
                            {isExtend === 'presence' ? (
                                <img
                                    style={{ marginLeft: '5px' }}
                                    src={downImg}
                                    width="14px"
                                    alt=""
                                />
                            ) : (
                                ''
                            )}
                        </button>
                        <button
                            onClick={() =>
                                setIsExtend((prev) => (prev === 'maintenance' ? '' : 'maintenance'))
                            }
                            type="button"
                            className="maintenance-btn survey-btn"
                        >
                            Maintenance{' '}
                            {isExtend === 'maintenance' ? (
                                <img
                                    style={{ marginLeft: '5px' }}
                                    src={downImg}
                                    width="14px"
                                    alt=""
                                />
                            ) : (
                                ''
                            )}
                        </button>
                    </div>
                </Row>
                {renderInformationData(isExtend)}
            </div>
        </>
    );
}

export default Item;
