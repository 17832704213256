/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Col } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch, useSelector } from 'react-redux';
import {
    mmArea,
    mmDistributionHouse,
    mmPoint,
    mmRegion,
    mmTerritory
} from '../../redux/features/filter/mmFilterSlice';

function MmFilter() {
    const dispatch = useDispatch();
    const { region, area, territory, distributionHouse, point } = useSelector(
        (state) => state.mmFilter
    );

    // filter disable
    const [disableFilter, setDisableFilter] = useState(false);

    const { accessToken } = useSelector((state) => state.auth);

    // filter main data
    const [filterData, setFilterData] = useState([]);

    const [action, setAction] = useState(false);

    // change filter value
    const handleSelect = (selectedList, eventName) => {
        if (eventName === 'regionList') {
            setAction((prev) => !prev);
            dispatch(mmRegion(selectedList));
            dispatch(mmArea([]));
            dispatch(mmTerritory([]));
            dispatch(mmDistributionHouse([]));
            dispatch(mmPoint([]));
        }
        if (eventName === 'areaList') {
            setAction((prev) => !prev);
            dispatch(mmArea(selectedList));
            dispatch(mmTerritory([]));
            dispatch(mmDistributionHouse([]));
            dispatch(mmPoint([]));
        }
        if (eventName === 'territoryList') {
            setAction((prev) => !prev);
            dispatch(mmTerritory(selectedList));
            dispatch(mmDistributionHouse([]));
            dispatch(mmPoint([]));
        }
        if (eventName === 'distributionHouseList') {
            setAction((prev) => !prev);
            dispatch(mmDistributionHouse(selectedList));
            dispatch(mmPoint([]));
        }
        if (eventName === 'pointList') dispatch(mmPoint(selectedList));
    };

    // filter data normalize and performance boost
    const data = useMemo(() => {
        const object = [
            region,
            area,
            territory,
            distributionHouse,
            point,
        ].reduce((prev, current, index) => {
            if (index === 0) {
                return current.length > 0 ? { ...prev, region: current.map((o) => o.value) } : prev;
            }
            if (index === 1) {
                return current.length > 0 ? { ...prev, area: current.map((o) => o.value) } : prev;
            }
            if (index === 2) {
                return current.length > 0
                    ? { ...prev, territory: current.map((o) => o.value) }
                    : prev;
            }
            if (index === 3) {
                return current.length > 0 ? { ...prev, distributionHouse: current.map((o) => o.value) } : prev;
            }
            if (index === 4) {
                return current.length > 0 ? { ...prev, point: current.map((o) => o.value) } : prev;
            }
        }, {});
        return object;
    }, [region, area, territory, distributionHouse, point]);

    // load filter data
    useEffect(() => {
        setDisableFilter(true);
        fetch('https://api.hedigital.app/api/markings/getMarkings', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify(data),
        })
            .then((res) => res.json())
            .then((d) => {
                setFilterData({ ...filterData, ...d });
                setDisableFilter(false);
            });
    }, [action]);

    if (filterData?.length <= 0) {
        return null
    };
    
    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <MultiSelect
                    labelledBy="Region"
                    hasSelectAll
                    options={filterData?.regionList?.length ? filterData?.regionList : []}
                    value={region}
                    onChange={(...args) => handleSelect(...args, 'regionList')}
                    className="multiselect-input"
                    disabled={disableFilter}
                    overrideStrings={{
                        allItemsAreSelected: 'All',
                        selectSomeItems: 'Region',
                    }}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <MultiSelect
                    hasSelectAll
                    options={filterData?.areaList?.length ? filterData?.areaList : []}
                    value={area}
                    onChange={(...args) => handleSelect(...args, 'areaList')}
                    labelledBy="Area"
                    className="multiselect-input"
                    disabled={disableFilter}
                    overrideStrings={{ allItemsAreSelected: 'All', selectSomeItems: 'Area' }}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <MultiSelect
                    hasSelectAll
                    options={
                        filterData?.territoryList?.length ? filterData?.territoryList : []}
                    value={territory}
                    onChange={(...args) => handleSelect(...args, 'territoryList')}
                    labelledBy="Territory"
                    className="multiselect-input"
                    disabled={disableFilter}
                    overrideStrings={{
                        allItemsAreSelected: 'All',
                        selectSomeItems: 'Territory',
                    }}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <MultiSelect
                    hasSelectAll
                    options={
                        filterData?.distributionHouseList?.length ?
                        filterData?.distributionHouseList : []
                    }
                    value={distributionHouse}
                    onChange={(...args) => handleSelect(...args, 'distributionHouseList')}
                    labelledBy="Distribution House"
                    className="multiselect-input"
                    disabled={disableFilter}
                    overrideStrings={{
                        allItemsAreSelected: 'All',
                        selectSomeItems: 'Distribution House',
                    }}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <MultiSelect
                    hasSelectAll
                    options={filterData?.pointList?.length ? filterData?.pointList : []}
                    value={point}
                    onChange={(...args) => handleSelect(...args, 'pointList')}
                    labelledBy="Point"
                    className="multiselect-input"
                    disabled={disableFilter}
                    overrideStrings={{ allItemsAreSelected: 'All', selectSomeItems: 'Point' }}
                />
            </Col>
        </>
    );
}

export default MmFilter;
