/* eslint-disable prettier/prettier */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pmmStatus: [],
  channel: [],
  subChannel: [],
  selectedBy: [],
};

const pmmMaintenanceFilterSlice = createSlice({
  name: "pmmMaintenanceFilter",
  initialState,
  reducers: {
    setPmmStatus: (state, action) => {
      state.pmmStatus = action.payload;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setSubChannel: (state, action) => {
      state.subChannel = action.payload;
    },
    setSelectedBy: (state, action) => {
        state.selectedBy = action.payload;
    },
    resetPmmMaintenanceFilter: (state, action) => {
      state.pmmStatus = [];
      state.channel = [];
      state.subChannel = [];
      state.selectedBy = [];
    },
  },
});

export default pmmMaintenanceFilterSlice.reducer;
export const {
  setPmmStatus,
  setChannel,
  setSubChannel,
  setSelectedBy,
  resetPmmMaintenanceFilter,
} = pmmMaintenanceFilterSlice.actions;
