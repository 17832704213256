/* eslint-disable no-underscore-dangle */
import { EditOutlined } from '@ant-design/icons';
import { Button, Modal, notification, Pagination, Space, Table, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { resetMappingFilter } from '../../redux/features/filter/cmMappingFilterSlice';
import { resetMMFilter } from '../../redux/features/filter/mmFilterSlice';
import Filter from '../Filter/Filter';
import InformationChange from './InformationChange';

function CmMapping() {
    const dispatch = useDispatch();
    const { user, accessToken } = useSelector((state) => state.auth);

    // loading state
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState([]);

    const [employeeCode, setEmployeeCode] = useState('');

    const { region, area, territory, distributionHouse, point } = useSelector(
        (state) => state.mmFilter
    );
    const { empName, empUsername, empLevel, empCode } = useSelector(
        (state) => state.cmMappingFilter
    );

    // antd notification state
    const [api, contextHolder] = notification.useNotification();
    const openNotificationWithIcon = (type, message, body) => {
        api[type]({
            duration: 0,
            message,
            description: body,
        });
    };

    // point data
    const [pointData, setPointData] = useState([]);

    // pagination
    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // initial load data
    useEffect(() => {
        setLoading(true);
        (async function () {
            try {
                const resultPromise = await fetch(
                    'https://api.hedigital.app/api/mappings/getUsers',
                    {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                const result = await resultPromise.json();
                setData(result);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        })();
    }, [accessToken]);

    const navigate = useNavigate();
    if (user?.employeeLevel === 'MS' || user?.employeeLevel === 'Agency') {
        setTimeout(() => {
            navigate('/');
        }, 1);
    }

    // get query data
    const getQueryData = () => {
        const bodyData = {};

        if (region.length) {
            bodyData.region = region?.map((r) => r.label);
        }
        if (area.length) {
            bodyData.area = area?.map((r) => r.label);
        }
        if (territory.length) {
            bodyData.territory = territory?.map((r) => r.label);
        }
        if (distributionHouse.length) {
            bodyData.distributionHouse = distributionHouse?.map((r) => r.label);
        }
        if (point.length) {
            bodyData.point = point?.map((r) => r.label);
        }
        if (empName) {
            bodyData.employeeName = empName;
        }
        if (empUsername) {
            bodyData.username = empUsername;
        }
        if (empLevel) {
            bodyData.employeeLevel = empLevel;
        }
        if (empCode) {
            bodyData.employeeCode = empCode;
        }
        return bodyData;
    };

    const fetchSurveyQueryData = async (page, totalShow, clean) => {
        setLoading(true);
        try {
            if (clean === 'cleanShowResultOnPage') {
                setCurrentPageShow(1);
                setTotalPageShow(10);
            }
            const resultPromise = await fetch(
                `https://api.hedigital.app/api/mappings/getUsers?page=${page}&limit=${totalShow}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(getQueryData()),
                }
            );
            const result = await resultPromise.json();
            setData(result);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    // reset existing filter
    useEffect(() => {
        dispatch(resetMMFilter());
        dispatch(resetMappingFilter());
    }, [dispatch]);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleOk = () => {
        setIsModalOpen(false);
        pointData.forEach((mapping) => {
            if (mapping?._id) {
                (async function () {
                    try {
                        const res = await fetch(
                            `https://api.hedigital.app/api/mappings/${mapping._id}`,
                            {
                                method: 'PATCH',
                                headers: {
                                    'Content-Type': 'application/json',
                                    Authorization: `Bearer ${accessToken}`,
                                },
                                body: JSON.stringify({
                                    point: mapping.point,
                                    routeSection: mapping.routeSection.join(', '),
                                }),
                            }
                        );
                        const result = await res.json();
                        if (result.message) {
                            throw new Error(result.message);
                        }
                        if (!result.message) {
                            openNotificationWithIcon(
                                'success',
                                'Successfully Updated',
                                `Updated ${mapping.point} - ${mapping.routeSection.join(', ')}`
                            );
                        }
                    } catch (error) {
                        openNotificationWithIcon(
                            'error',
                            'Error Updated',
                            `Error ${mapping.point} - ${mapping.routeSection.join(', ')}`
                        );
                    } finally {
                        fetchSurveyQueryData(currentPage, totalShowPage);
                    }
                })();
            } else {
                (async function () {
                    try {
                        const res = await fetch(`https://api.hedigital.app/api/mappings`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${accessToken}`,
                            },
                            body: JSON.stringify({
                                cmCode: employeeCode,
                                point: mapping.point,
                                routeSection: mapping.routeSection.join(', '),
                            }),
                        });
                        const result = await res.json();
                        if (result.message) {
                            throw new Error(result.message);
                        }
                        if (!result.message) {
                            openNotificationWithIcon(
                                'success',
                                'Successfully created',
                                `New point  ${
                                    mapping.point
                                } and route/section ${mapping.routeSection.join(', ')}`
                            );
                        }
                    } catch (error) {
                        openNotificationWithIcon(
                            'error',
                            'Failed to add new route/section',
                            `Error ${mapping.point} - ${mapping.routeSection.join(', ')}`
                        );
                    } finally {
                        fetchSurveyQueryData(currentPage, totalShowPage);
                    }
                })();
            }
        });
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // table columns
    const columns = [
        {
            title: 'Name',
            dataIndex: 'employeeName',
            key: 'employeeName',
        },
        {
            title: 'S Name',
            dataIndex: 'supervisorName',
            key: 'supervisorName',
        },
        {
            title: 'Code',
            dataIndex: 'employeeCode',
            key: 'employeeCode',
        },
        {
            title: 'Level',
            dataIndex: 'employeeLevel',
            key: 'employeeLevel',
        },
        {
            title: 'Region',
            dataIndex: 'region',
            key: 'region',
        },
        {
            title: 'Area',
            dataIndex: 'area',
            key: 'area',
        },
        {
            title: 'Territory',
            dataIndex: 'territory',
            key: 'territory',
        },
        {
            title: 'Dist House',
            dataIndex: 'distributionHouse',
            key: 'distributionHouse',
        },
        {
            title: 'Points',
            dataIndex: 'points',
            key: 'points',
        },
        {
            title: 'UN',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'PW',
            dataIndex: 'password',
            key: 'password',
        },
        {
            title: 'Action',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button
                        type="primary"
                        shape="circle"
                        icon={<EditOutlined />}
                        onClick={() => {
                            setEmployeeCode(record.employeeCode);
                            setIsModalOpen((prev) => !prev);
                        }}
                    />
                </Space>
            ),
        },
    ];

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchSurveyQueryData(pageNumber, totalPageChange);
    };

    return (
        <>
            {contextHolder}
            <div style={{ margin: '16px 0' }}>
                <Filter surveyDataFetch={fetchSurveyQueryData} pathname="/cm-mapping" />
            </div>
            <Modal
                title="Edit Employee Mapping"
                okText="Save"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={615}
            >
                <InformationChange
                    employeeCode={employeeCode}
                    setPointData={setPointData}
                    pointData={pointData}
                />
            </Modal>
            <div
                style={{
                    padding: 15,
                    minHeight: 400,
                    background: colorBgContainer,
                }}
            >
                <Table
                    rowKey="username"
                    size="small"
                    loading={loading}
                    pagination={false}
                    columns={columns}
                    dataSource={data?.data}
                />
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                <Pagination
                    size="large"
                    showQuickJumper
                    showSizeChanger={false}
                    current={currentPage}
                    defaultCurrent={1}
                    total={data?.totalCount}
                    onChange={onChange}
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                />
            </div>
        </>
    );
}

export default CmMapping;
