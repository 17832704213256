/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { Row } from 'antd';
import React from 'react';

import { useDispatch } from 'react-redux';
import DashboardFilter from './DashboardFilter';
import DownloadFilter from './DownloadFilter';
import EmployeeFilter from './EmployeeFilter';
import MmFilter from './MmFilter';
import PmmMaintenanceFilter from './PmmMaintenanceFilter';
import SurveyFilter from './SurveyFilter';

function Filter({ pathname, surveyDataFetch }) {
    const dispatch = useDispatch();
    const activePageFilter = () => {
        let content;
        switch (pathname) {
            case '/':
                content = <DashboardFilter surveyDataFetch={surveyDataFetch} />;
                break;
            case '/survey':
                content = <SurveyFilter surveyDataFetch={surveyDataFetch} />;
                break;
            case '/pmm-maintenance':
                content = <PmmMaintenanceFilter surveyDataFetch={surveyDataFetch} />;
                break;
            case '/cm-mapping':
                content = <EmployeeFilter surveyDataFetch={surveyDataFetch} />;
                break;
            case '/download-report':
                content = <DownloadFilter surveyDataFetch={surveyDataFetch} />;
                break;
            default:
                break;
        }
        return content;
    };

    return (
        <Row gutter={[5, 5]}>
            <MmFilter />
            {activePageFilter()}
        </Row>
    );
}

export default Filter;
