/* eslint-disable prettier/prettier */
import { FilterOutlined } from "@ant-design/icons";
import { Button, Col, Input, Select } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAiEvaluation,
  setChannel,
  setEvaluationType,
  setMaintenance,
  setMaterialType,
  setMcEvaluation,
  setMsCallType,
  setOpportunity,
  setOwnerName,
  setPresence,
  setPriority,
  setSubChannel,
  setSurveyRouteSection,
  surveyCmCode,
  surveyEndDate,
  surveyEvaluation,
  surveyLocationMatch,
  surveyMsCode,
  surveyOutletCode,
  surveyStartDate
} from "../../redux/features/filter/surveyFilterSlice";
import DateRange from "./DateRange";

function SurveyFilter({ surveyDataFetch }) {
  const dispatch = useDispatch();

  const {
    user: { employeeLevel },
  } = useSelector(state => state.auth);

  const startDateFn = (_, dateString) => {
    dispatch(surveyStartDate(dateString));
  };

  const endDateFn = (_, dateString) => {
    dispatch(surveyEndDate(dateString));
  };

  return (
    <>
      <DateRange startFn={startDateFn} endFn={endDateFn} />
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    mode="multiple"
                    placeholder="Location Match"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(surveyLocationMatch(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
            </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    mode="multiple"
                    placeholder="MS Evaluation"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(surveyEvaluation(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    allowClear
                    showArrow
                    mode="multiple"
                    placeholder="MC Evaluation"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(setMcEvaluation(e))}
                    options={[
                        {
                            value: 'Yes',
                            label: 'Yes',
                        },
                        {
                            value: 'No',
                            label: 'No',
                        },
                    ]}
                />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                    <Select
                            allowClear
                            showArrow
                            mode="multiple"
                            placeholder="Presence"
                            size="large"
                            style={{
                                width: '100%',
                            }}
                            onChange={(e) => dispatch(setPresence(e))}
                            options={[
                                {
                                    value: 'Yes',
                                    label: 'Yes',
                                },
                                {
                                    value: 'No',
                                    label: 'No',
                                },
                            ]}
                        />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="Opportunity"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setOpportunity(e))}
                        options={[
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                            {
                                value: 'No',
                                label: 'No',
                            },
                        ]}
                    />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="Maintenance"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setMaintenance(e))}
                        options={[
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                            {
                                value: 'No',
                                label: 'No',
                            },
                        ]}
                    />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="Owner Name"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setOwnerName(e))}
                        options={[
                            {
                                value: 'BATB',
                                label: 'BATB',
                            },
                            {
                                value: 'JTI',
                                label: 'JTI',
                            },
                            {
                                value: 'AKTC',
                                label: 'AKTC',
                            },
                            {
                                value: 'BROWN',
                                label: 'BROWN',
                            },
                            {
                                value: 'PMI',
                                label: 'PMI',
                            },
                            {
                                value: 'VBP SPONSOR',
                                label: 'VBP SPONSOR',
                            },
                        ]}
                    />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="Material Type"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setMaterialType(e))}
                        options={[
                            {
                                value: 'POSM',
                                label: 'POSM',
                            },
                            {
                                value: 'Planogram',
                                label: 'Planogram',
                            },
                            {
                                value: 'PMM',
                                label: 'PMM',
                            },
                        ]}
                    />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="MS Call Type"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setMsCallType(e))}
                        options={[
                            {
                                value: 'Market Visit call',
                                label: 'Market Visit call',
                            },
                            {
                                value: 'Virtual call',
                                label: 'Virtual call',
                            },
                        ]}
                    />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="Evaluation Type"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setEvaluationType(e))}
                        options={[
                            {
                                value: 'Edited',
                                label: 'Edited',
                            },
                            {
                                value: 'Approved',
                                label: 'Approved',
                            },
                        ]}
                    />
                </Col>
                <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                        allowClear
                        showArrow
                        mode="multiple"
                        placeholder="AI Evaluation"
                        size="large"
                        style={{
                            width: '100%',
                        }}
                        onChange={(e) => dispatch(setAiEvaluation(e))}
                        options={[
                            {
                                value: 'Yes',
                                label: 'Yes',
                            },
                            {
                                value: 'No',
                                label: 'No',
                            },
                        ]}
                    />
                </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input 
                placeholder="MS Code" 
                size="large" 
                style={{ width: '100%' }}
                onChange={(e) => dispatch(surveyMsCode(e.target.value))} 
            />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input 
                placeholder="CM Code" 
                size="large" 
                style={{ width: '100%' }}
                onChange={(e) => dispatch(surveyCmCode(e.target.value))} 
            />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input 
                placeholder="Route/Section" 
                size="large" 
                style={{ width: '100%' }}
                onChange={(e) => dispatch(setSurveyRouteSection(e.target.value))} 
            />
            </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Input
          placeholder="Outlet Code"
          size="large"
          style={{ width: "100%" }}
          onChange={e => dispatch(surveyOutletCode(e.target.value))}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Select
          allowClear
          showArrow
          mode="multiple"
          placeholder="Priority"
          size="large"
          style={{
            width: "100%",
          }}
          onChange={e => dispatch(setPriority(e))}
          options={[
            {
              value: "Priority-1",
              label: "Priority-1",
            },
            {
              value: "Priority-2",
              label: "Priority-2",
            },
            {
              value: "Priority-3",
              label: "Priority-3",
            },
            {
              value: "Rest",
              label: "Rest",
            },
          ]}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Select
          allowClear
          showArrow
          mode="multiple"
          placeholder="Channel"
          size="large"
          style={{
            width: "100%",
          }}
          onChange={e => dispatch(setChannel(e))}
          options={[
            {
              value: "C&C Channel",
              label: "C&C Channel",
            },
            {
              value: "GT Channel",
              label: "GT Channel",
            },
            {
              value: "TLP Channel",
              label: "TLP Channel",
            },
          ]}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Select
          allowClear
          showArrow
          mode="multiple"
          placeholder="Sub Channel"
          size="large"
          style={{
            width: "100%",
          }}
          onChange={e => dispatch(setSubChannel(e))}
          options={[
            {
              value: "BCC",
              label: "BCC",
            },
            {
              value: "GT",
              label: "GT",
            },
            {
              value: "MVP",
              label: "MVP",
            },
            {
              value: "P",
              label: "P",
            },
            {
              value: "RCC",
              label: "RCC",
            },
            {
              value: "VP",
              label: "VP",
            },
          ]}
        />
      </Col>
      <Col xs={12} sm={8} md={6} lg={6} xl={6}>
        <Button
          size="large"
          className="filter-btn"
          icon={<FilterOutlined />}
          type="primary"
          onClick={() => surveyDataFetch(1, 10, "cleanShowResultOnPage")}
        >
          Filter
        </Button>
      </Col>
    </>
  );
}

export default SurveyFilter;
