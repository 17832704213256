/* eslint-disable prettier/prettier */
/* eslint-disable react-hooks/exhaustive-deps */
import { notification, Spin, theme } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
    resetDownloadFilter,
    setGlobalLoading
} from '../../redux/features/filter/downloadReportFilterSlice';
import { resetMMFilter } from '../../redux/features/filter/mmFilterSlice';
import Filter from '../Filter/Filter';
import getGeolocationReport from './xlxs/geolocationReport';
import getMCEvaluationReport from './xlxs/mcEvaluationReport';
import getMerchandisingOppurtinityPlanogramReport from './xlxs/merchandisingOppurtinityPlanogramReport';
import getMerchandisingOppurtinityPMMReport from './xlxs/merchandisingOppurtinityPMMReport';
import getMerchandisingOppurtinityPOSMReport from './xlxs/merchandisingOppurtinityPOSMReport';
import getMerchandisingPresencePlanogramReport from './xlxs/merchandisingPresencePlanogramReport';
import getMerchandisingPresencePMMMaintenanceReport from './xlxs/merchandisingPresencePMMMaintenanceReport';
import getMerchandisingPresencePmmReport from './xlxs/merchandisingPresencePmmReport';
import getMerchandisingPresencePOSMReport from './xlxs/merchandisingPresencePOSMReport';
import getMSEvaluationReport from './xlxs/msEvaluationReport';
import getPlanogramAiReport from './xlxs/planogramAiReport';
import getPmmAiReport from './xlxs/pmmAiReport';
import getPmmMaintenanceReport from './xlxs/pmmMaintenanceReport';
import getPosmAiReport from './xlxs/posmAiReport';
import getSovAiReport from './xlxs/sovAiResult';

function DownloadReport() {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.auth);
    const { region, area, territory, distributionHouse, point } = useSelector(
        (state) => state.mmFilter
    );
    const { globalLoading, reportType, startDate, endDate, report } = useSelector((state) => state.downloadFilter);
        
    const { accessToken } = useSelector((state) => state.auth);


    const navigate = useNavigate();
    if (!user?.level === 'Admin') {
        setTimeout(() => {
            navigate('/');
        }, 1);
    }

    // reset existing filter
    useEffect(() => {
        dispatch(resetMMFilter());
        dispatch(resetDownloadFilter());
    }, [dispatch]);

    const [loading, setLoading] = useState(false);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    // error notificaiton
    const [api, contextHolder] = notification.useNotification();
    const openNotification = () => {
        api.warning({
        message: `Warning`,
        description: 'Please select a report type.',
        placement: 'topRight'
        });
    };

    function getBodyData() {
        const bodyData = {
            report: report === 'previousReport' ? 'previous' : 'current'
        };
        if (region.length) {
            bodyData.region = region?.map((r) => r.label);
        }
        if (area.length) {
            bodyData.area = area?.map((r) => r.label);
        }
        if (territory.length) {
            bodyData.territory = territory?.map((r) => r.label);
        }
        if (distributionHouse.length) {
            bodyData.distributionHouse = distributionHouse?.map((r) => r.label);
        }
        if (point.length) {
            bodyData.point = point?.map((r) => r.label);
        }
        if (startDate || endDate) {
            if (!startDate && endDate) {
                bodyData.createdAt = [
                    "", 
                    moment(endDate).toJSON() || new Date().toJSON()
                ];
            } else {
                bodyData.createdAt = [
                    moment(startDate).toJSON() || new Date().toJSON(), 
                    moment(endDate).toJSON() || new Date().toJSON()
                ];
            }
            
        }
        return bodyData;
    }

    const fetchSurveyQueryData =  () => {
            if (reportType === 'getMerchandisingPresencePMMReport') {
                getMerchandisingPresencePmmReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMerchandisingPresencePOSMReport') {
                getMerchandisingPresencePOSMReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMerchandisingPresencePlanogramReport') {
                getMerchandisingPresencePlanogramReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMerchandisingPresencePMMMaintenanceReport') {
                getMerchandisingPresencePMMMaintenanceReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMerchandisingOppurtinityPMMReport') {
                getMerchandisingOppurtinityPMMReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMerchandisingOppurtinityPOSMReport') {
                getMerchandisingOppurtinityPOSMReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMerchandisingOppurtinityPlanogramReport') {
                getMerchandisingOppurtinityPlanogramReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'geoLocationReport') {
                getGeolocationReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMSEvaluationReport') {
                getMSEvaluationReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getMCEvaluationReport') {
                getMCEvaluationReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getPmmAiReport') {
                getPmmAiReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getPlanogramAiReport') {
                getPlanogramAiReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getPosmAiReport') {
                getPosmAiReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getSovAiReport') {
                getSovAiReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            } else if (reportType === 'getPmmMaintenanceReport') {
                getPmmMaintenanceReport(
                    getBodyData(),
                    accessToken,
                    (done) => dispatch(setGlobalLoading(!done))
                )
            }
            else {
                openNotification();
            }
    };

    return (
        <>
            {contextHolder}
            <div style={{ margin: '16px 0' }}>
                <Filter surveyDataFetch={fetchSurveyQueryData} pathname="/download-report" />
            </div>
            <div
                style={{
                    padding: 15,
                    minHeight: 400,
                    background: colorBgContainer,
                }}
            >
                <div
                    style={{ width: '100%', height: '50vh', display: 'grid', placeItems: 'center' }}
                >
                    {globalLoading ? <Spin size="large" /> : null}
                </div>
            </div>
        </>
    );
}

export default DownloadReport;
