/* eslint-disable no-useless-escape */
/* eslint-disable camelcase */
/* eslint-disable import/no-extraneous-dependencies */
import { Button, Col, Form, Input, Row, message } from 'antd';
import jwt_decode from 'jwt-decode';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.svg';
import { userLoggedIn } from '../redux/features/auth/authSlice';

function Login() {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [token, setToken] = useState(null);

    const [isPassReset, setIsPassReset] = useState(false);
    const [isPrivileged, setIsPrivileged] = useState(false);

    const successLogin = (accessToken) => {
        const decoded = jwt_decode(accessToken);
        message.success('Login Successfully');
        const authData = {
            accessToken,
            user: decoded,
        };
        sessionStorage.setItem('auth', JSON.stringify(authData));
        dispatch(userLoggedIn(authData));
        navigate('/pmm-maintenance');
    };

    const onFinish = async (values) => {
        setLoading(true);
        try {
            if (values.newPass && values.retypeNewPassword) {
                if (values.newPass === values.retypeNewPassword) {
                    const result = await fetch(
                        'https://api.hedigital.app/api/admins/update-password',
                        {
                            method: 'PATCH',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify({
                                password: values.newPass,
                            }),
                        }
                    );
                    const resultData = await result.json();
                    if (resultData.access_token) {
                        successLogin(resultData.access_token);
                    }
                    if (resultData.message) {
                        message.error(resultData.message);
                    }
                } else {
                    message.error('Password does not match');
                }
            } else {
                const resultPromise = await fetch('https://api.hedigital.app/api/admins/login', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(values),
                });
                const result = await resultPromise.json();
                if (result.message) {
                    message.error(result.message, 10);
                }
                if (result.access_token) {
                    const decoded = jwt_decode(result?.access_token);
                    if (decoded.newPasswordRequired) {
                        setIsPassReset(true);
                        setToken(result.access_token);
                        setIsPrivileged(decoded.previlegedAccess === 'Yes');
                    }
                    if (!decoded.newPasswordRequired) {
                        successLogin(result.access_token);
                    }
                }
            }
        } catch (error) {
            message.error(error.message);
        } finally {
            setLoading(false);
        }
    };
    return (
        <div className="main-login-container">
            <Row className="login-content" style={{ width: '720px', height: '500px' }}>
                <Col className="login-left-container" sm={24} xs={24} md={12} lg={12}>
                    <div className="login-left">
                        <img src={logo} alt="HawkEyes Logo" />
                    </div>
                </Col>
                <Col sm={24} xs={24} md={12} lg={12}>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <div className="login-text">
                            <h1>{isPassReset ? 'Update Password' : 'SIGN IN'}</h1>
                        </div>

                        <div className="login-input-field">
                            {isPassReset ? (
                                <>
                                    <Form.Item
                                        name="newPass"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                            {
                                                max: 64,
                                                message: 'Maximum 64 characters',
                                            },
                                            {
                                                pattern: isPrivileged
                                                    ? /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){16,64}$/gm
                                                    : /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{12,64})\S$/gm,
                                                message: `Minimum ${
                                                    isPrivileged ? '16' : '12'
                                                } and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character`,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            style={{ border: 'none' }}
                                            type="password"
                                            placeholder="New Password"
                                            size="large"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="retypeNewPassword"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                            {
                                                max: 64,
                                                message: 'Maximum 64 characters',
                                            },
                                            {
                                                pattern: isPrivileged
                                                    ? /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){16,64}$/gm
                                                    : /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{12,64})\S$/gm,
                                                message: `Minimum ${
                                                    isPrivileged ? '16' : '12'
                                                } and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character`,
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            style={{ border: 'none' }}
                                            type="password"
                                            placeholder="Retype New Password"
                                            size="large"
                                        />
                                    </Form.Item>
                                </>
                            ) : (
                                <>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your email!',
                                            },
                                            {
                                                pattern:
                                                    /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/gm,
                                                message: 'Please provide a valid email address',
                                            },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                border: 'none',
                                                marginBottom: '5px!Important',
                                            }}
                                            size="large"
                                            placeholder="Email Address"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please input your Password!',
                                            },
                                            {
                                                max: 64,
                                                message: 'Maximum 64 characters',
                                            },
                                            {
                                                pattern:
                                                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{12,}$/gm,
                                                message:
                                                    'Minimum 12 and maximum 64 characters, at least one uppercase letter, one lowercase letter, one number and one special character',
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            style={{ border: 'none' }}
                                            type="password"
                                            placeholder="Password"
                                            size="large"
                                        />
                                    </Form.Item>
                                </>
                            )}
                        </div>

                        <div className="login-submit">
                            <Form.Item>
                                <Button
                                    loading={loading}
                                    size="large"
                                    type="primary"
                                    htmlType="submit"
                                    className="login-form-button"
                                >
                                    {isPassReset ? 'UPDATE' : 'LOGIN'}
                                </Button>
                            </Form.Item>
                        </div>
                    </Form>
                </Col>
            </Row>
        </div>
    );
}

export default Login;
