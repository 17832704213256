/* eslint-disable prettier/prettier */
/* eslint-disable react/no-array-index-key */
import { EnvironmentOutlined } from "@ant-design/icons";
import { Button, Col, Image, Modal, Row } from "antd";
import moment from "moment/moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import downImg from "../../assets/down.png";
import AiResult from "./AiResult";
import MaintenanceInformation from "./MaintenanceInformation";
import MapModal from "./MapModal";
import OpportunityInformation from "./OpportunityInformation";
import PresenceInformation from "./PresenceInformation";

function SurveyItem({ data }) {
  const {
    executionInfo,
    merchandisingOpportunity,
    merchandisingPresence,
    aiRun,
  } = data;

  // execution information desctructured
  const {
    point,
    cmName,
    outletName,
    outletCode,
    supervisorCode,
    cmCode,
    clusterName,
    contactNumber,
    routeSection,
    locationMatch,
    distance,
    channel,
    subChannel,
    pmmStatus,
    status,
    duration,
    evaluationType,
  } = executionInfo;

  const {
    user: { roles },
  } = useSelector(state => state.auth);

  // image generate form survey information
  const generateImage = [
    ...merchandisingPresence.pmm,
    ...merchandisingPresence.posm,
    ...merchandisingPresence.planogram,
    ...merchandisingOpportunity.pmm,
    ...merchandisingOpportunity.posm,
    ...merchandisingOpportunity.planogram,
  ]
    ?.map(item =>
      item?.imageURLs?.map(img => ({ title: img?.name, url: img?.url }))
    )
    .flat();
  generateImage.unshift({
    title: executionInfo.image.name,
    url: executionInfo.image.url,
  });

  // expend state
  const [isExtend, setIsExtend] = useState("");

  // modal state and function
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  // ex location modal
  const [isExModalOpen, setExIsModalOpen] = useState(false);
  const showExModal = () => {
    setExIsModalOpen(true);
  };
  const handleExOk = () => {
    setExIsModalOpen(false);
  };
  const handleExCancel = () => {
    setExIsModalOpen(false);
  };

  const renderInformationData = str => {
    let info;
    if (str === "presence") {
      info = <PresenceInformation info={merchandisingPresence} />;
    } else if (str === "opportunity") {
      info = <OpportunityInformation info={merchandisingOpportunity} />;
    } else if (str === "maintenance") {
      info = (
        <MaintenanceInformation info={merchandisingPresence?.pmmMaintenance} />
      );
    } else if (str === "aiResult") {
      info = <AiResult info={merchandisingPresence} />;
    } else {
      info = null;
    }
    return info;
  };

  return (
    <>
      <Modal
        footer={false}
        title="Location in google Map"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width="850px"
        bodyStyle={{ padding: 0 }}
      >
        <MapModal
          lat={executionInfo?.newLocation?.latitude}
          lon={executionInfo?.newLocation?.longitude}
        />
      </Modal>

      <div
        className={`survey-item ${
          locationMatch === "No" ? "notMatch" : "match"
        }`}
      >
        <Row gap={[5, 5]} justify="space-between">
          <Col lg={6} md={12} sm={24} className="item-info-title">
            <p>
              <span className="bold-font">Point: </span>
              {point}
            </p>
            <p>
              <span className="bold-font">CM Name: </span>
              {cmName}
            </p>
            <p>
              <span className="bold-font">CM Code: </span>
              {cmCode}
            </p>
            <p>
              <span className="bold-font">MS Code: </span>
              {supervisorCode}
            </p>
            <p>
              <span className="bold-font">Outlet Code: </span>
              {outletCode}
            </p>
            {/* <p>
              <span className="bold-font">PMM Status: </span>
              {pmmStatus || ""}
            </p> */}
          </Col>
          <Col lg={6} md={12} sm={24} className="left-border item-info-title">
            <p>
              <span className="bold-font">Cluster: </span>
              {clusterName}
            </p>
            <p>
              <span className="bold-font">Outlet Name: </span>
              {outletName}
            </p>
            <p>
              <span className="bold-font">Mobile: </span>
              {contactNumber}
            </p>
            <p>
              <span className="bold-font">Route: </span>
              {routeSection}
            </p>
            <p>
              <span className="bold-font">Channel: </span>
              {channel || ""}
            </p>
            <p>
              <span className="bold-font">Sub Channel: </span>
              {subChannel || ""}
            </p>
            {roles === "HEDML" ? (
              <p>
                <span className="bold-font">AI Run: </span>
                {aiRun ? "True" : "false"}
              </p>
            ) : null}
          </Col>
          <Col lg={6} md={12} sm={24} className="left-border item-info-title">
            <p>
              <span className="bold-font">Time: </span>
              {moment(data.createdAt).format("LT")}
            </p>
            <p>
              <span className="bold-font">Date: </span>
              {moment(data.createdAt).format("ll")}
            </p>
            <p>
              <span className="bold-font">Match: </span>
              {locationMatch}
            </p>
            <p>
              <span className="bold-font">Duration: </span>
              {duration || ""}
            </p>
            <p>
              <span className="bold-font">Distance: </span>
              {distance}
            </p>
            <p>
              <span className="bold-font">Evaluation: </span>
              {evaluationType || "Pending"}
            </p>
          </Col>
          <Col lg={6} md={12} sm={24} className="left-border">
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <Button type="primary" danger style={{ marginRight: '5px' }}>
                                Evaluate
                            </Button> */}
              <Button
                ghost
                title="New Location"
                onClick={() => setIsModalOpen(prev => !prev)}
              >
                <EnvironmentOutlined />
              </Button>
            </div>
            <div className="survey-last-section">
              <Image.PreviewGroup
                preview={{
                  countRender: current =>
                    generateImage[current - 1]?.title || "null",
                }}
              >
                <Row gutter={[2, 2]}>
                  {generateImage?.map((image, i) => (
                    <Col key={image.url + i}>
                      <Image width={45} src={image.url} />
                    </Col>
                  ))}
                </Row>
              </Image.PreviewGroup>
            </div>
          </Col>
          <div className="btn-container">
            <button
              onClick={() =>
                setIsExtend(prev => (prev === "presence" ? "" : "presence"))
              }
              type="button"
              className="presence-btn survey-btn"
            >
              Presence{" "}
              {isExtend === "presence" ? (
                <img
                  style={{ marginLeft: "5px" }}
                  src={downImg}
                  width="14px"
                  alt=""
                />
              ) : (
                ""
              )}
            </button>
            <button
              onClick={() =>
                setIsExtend(prev =>
                  prev === "opportunity" ? "" : "opportunity"
                )
              }
              type="button"
              className="opportunity-btn survey-btn"
            >
              Opportunity{" "}
              {isExtend === "opportunity" ? (
                <img
                  style={{ marginLeft: "5px" }}
                  src={downImg}
                  width="14px"
                  alt=""
                />
              ) : (
                ""
              )}
            </button>
            <button
              onClick={() =>
                setIsExtend(prev =>
                  prev === "maintenance" ? "" : "maintenance"
                )
              }
              type="button"
              className="maintenance-btn survey-btn"
            >
              Maintenance{" "}
              {isExtend === "maintenance" ? (
                <img
                  style={{ marginLeft: "5px" }}
                  src={downImg}
                  width="14px"
                  alt=""
                />
              ) : (
                ""
              )}
            </button>
            <button
              onClick={() => {
                if (aiRun) {
                  if (
                    roles === "HEDML") {
                    setIsExtend(prev =>
                      prev === "aiResult" ? "" : "aiResult"
                    );
                  }
                }
              }}
              type="button"
              className="aiResult-btn survey-btn"
            >
              AI Result{" "}
              {isExtend === "aiResult" ? (
                <img
                  style={{ marginLeft: "5px" }}
                  src={downImg}
                  width="14px"
                  alt=""
                />
              ) : (
                ""
              )}
            </button>
          </div>
        </Row>
        {renderInformationData(isExtend)}
      </div>
    </>
  );
}

export default SurveyItem;
