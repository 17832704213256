/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    region: [],
    area: [],
    territory: [],
    distributionHouse: [],
    point: [],
};

const mmFilterSlice = createSlice({
    name: 'mmFilterSlice',
    initialState,
    reducers: {
        mmRegion: (state, action) => {
            state.region = action.payload;
        },
        mmArea: (state, action) => {
            state.area = action.payload;
        },
        mmTerritory: (state, action) => {
            state.territory = action.payload;
        },
        mmDistributionHouse: (state, action) => {
            state.distributionHouse = action.payload;
        },
        mmPoint: (state, action) => {
            state.point = action.payload;
        },
        resetMMFilter: (state, action) => {
            state.region = [];
            state.area = [];
            state.territory = [];
            state.distributionHouse = [];
            state.point = [];
        },
    },
});

export default mmFilterSlice.reducer;
export const { mmArea, mmDistributionHouse, mmPoint, mmRegion, mmTerritory, resetMMFilter } =
    mmFilterSlice.actions;
