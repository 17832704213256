/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Checkbox, Pagination, Space, Spin, message, theme } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetMMFilter } from '../../redux/features/filter/mmFilterSlice';
import { resetPmmMaintenanceFilter } from '../../redux/features/filter/pmmMaintenanceFilterSlice';
import NoResult from '../../ui/NoResult';
import Filter from '../Filter/Filter';
import Item from './Item';

function PmmMaintenance() {
    const dispatch = useDispatch();
    // survey filter data
    const { region, area, territory, distributionHouse, point } = useSelector(
        (state) => state.mmFilter
    );
    const { pmmStatus, channel, subChannel, selectedBy } = useSelector(
        (state) => state.pmmMaintenanceFilter
    );

    const { accessToken, user } = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);

    const { pathname } = useLocation();

    // pagination
    const [totalShowPage, setTotalPageShow] = useState(10);
    const [currentPage, setCurrentPageShow] = useState(1);

    // data
    const [data, setData] = useState([]);

    // reset existing filter
    useEffect(() => {
        dispatch(resetMMFilter());
        dispatch(resetPmmMaintenanceFilter());
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        fetch(`https://api.hedigital.app/api/surveys/getSurveyData?page=1&limit=${totalShowPage}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${accessToken}`,
            },
            body: JSON.stringify({ pmmStatus: 'All' }),
        })
            .then((res) => res.json())
            .then((result) => {
                setData(result);
                setLoading(false);
            });
    }, []);

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    // aiEvaluation: [],

    // survey query data
    const getSurveyQueryData = () => {
        const bodyData = {};

        if (region.length) {
            bodyData.region = region?.map((r) => r.label);
        }
        if (area.length) {
            bodyData.area = area?.map((r) => r.label);
        }
        if (territory.length) {
            bodyData.territory = territory?.map((r) => r.label);
        }
        if (distributionHouse.length) {
            bodyData.distributionHouse = distributionHouse?.map((r) => r.label);
        }
        if (point.length) {
            bodyData.point = point?.map((r) => r.label);
        }
        if (pmmStatus.length) {
            bodyData.pmmStatus = pmmStatus;
        }
        if (pmmStatus.length <= 0) {
            bodyData.pmmStatus = 'All';
        }
        if (channel.length) {
            bodyData.channel = channel;
        }
        if (subChannel.length) {
            bodyData.subChannel = subChannel;
        }
        if (selectedBy.length) {
            bodyData.selectedBy = selectedBy;
        }

        return bodyData;
    };

    const fetchSurveyQueryData = async (page, totalShow, clean) => {
        setLoading(true);
        try {
            if (clean === 'cleanShowResultOnPage') {
                setCurrentPageShow(1);
                setTotalPageShow(10);
            }
            setLoading(true);
            const fetchData = await fetch(
                `https://api.hedigital.app/api/surveys/getSurveyData?page=${page}&limit=${totalShow}`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(getSurveyQueryData()),
                }
            );
            const resultData = await fetchData.json();
            setData(resultData);
        } catch (error) {
            setLoading(true);
        } finally {
            setLoading(false);
        }
    };

    const onChange = (pageNumber, totalPageChange) => {
        setTotalPageShow(() => totalPageChange);
        setCurrentPageShow(pageNumber);
        fetchSurveyQueryData(pageNumber, totalPageChange);
    };

    // check item is already selected
    function isDisabled(lev, approve, select, rejected) {
        if (lev === 'TO') {
            return JSON.stringify(select) === 'true';
        }
        if (lev === 'AM') {
            if (JSON.stringify(approve) === 'true') {
                return true;
            }
            if (JSON.stringify(rejected) === 'true') {
                return true;
            }
            return false;
        }
    }

    // selected by to condition start
    const [updateCheckedLoading, setUpdateCheckedLoading] = useState(false);
    const [checkedList, setCheckedList] = useState([]);
    const [indeterminate, setIndeterminate] = useState(false);
    const [checkAll, setCheckAll] = useState(false);

    const onCheckAllChange = (e) => {
        setCheckedList(
            e.target.checked
                ? data?.data
                      ?.filter(
                          ({ executionInfo }) =>
                              !isDisabled(
                                  user.level,
                                  executionInfo?.approved,
                                  executionInfo?.selected,
                                  executionInfo?.rejected
                              )
                      )
                      ?.map((item) => item._id)
                : []
        );
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    useEffect(() => {
        const filteredChecked = data?.data?.filter(
            ({ executionInfo }) =>
                !isDisabled(
                    user.level,
                    executionInfo?.approved,
                    executionInfo?.selected,
                    executionInfo?.rejected
                )
        );
        setCheckAll(filteredChecked?.map((item) => item._id).length === checkedList.length);
        setIndeterminate(() => {
            if (checkedList.length === 0) {
                return false;
            }
            if (checkedList.length === filteredChecked?.map((item) => item._id).length) {
                return false;
            }
            return true;
        });
    }, [checkedList]);

    // selected by to condition end

    // submit selected item
    const submitSelectedItems = async (isSelectedOrApproved) => {
        setUpdateCheckedLoading(true);
        try {
            const result = await fetch('https://api.hedigital.app/api/surveys/update-by-admin', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    outlets: checkedList.map((item) => ({ id: item, ...isSelectedOrApproved })),
                }),
            });
            const resultData = await result.json();
            setCheckedList([]);
            setCheckAll(false);
            setData((prev) => ({
                ...prev,
                data: prev.data.map((item) =>
                    resultData.find((d) => d._id === item._id)
                        ? resultData.find((d) => d._id === item._id)
                        : { ...item }
                ),
            }));
            message.success('Successfully updated');
        } catch (error) {
            message.error(error.message);
        } finally {
            setUpdateCheckedLoading(false);
        }
    };

    return (
        <>
            <div style={{ margin: '16px 0' }}>
                <Filter surveyDataFetch={fetchSurveyQueryData} pathname={pathname} />
            </div>
            <div
                style={{
                    padding: 15,
                    minHeight: 400,
                    background: colorBgContainer,
                }}
            >
                {!loading ? (
                    <>
                        {data?.data?.length <= 0 ? (
                            <NoResult />
                        ) : (
                            <>
                                {user.level === 'TO' || user.level === 'AM' ? (
                                    <div className="select-all-header">
                                        <>
                                            <div>
                                                <Checkbox
                                                    indeterminate={indeterminate}
                                                    onChange={onCheckAllChange}
                                                    checked={checkAll}
                                                >
                                                    {checkAll ? 'All' : 'All'}
                                                </Checkbox>
                                                {updateCheckedLoading ? <Spin /> : null}
                                            </div>
                                            {checkedList.length ? (
                                                <>
                                                    <Space wrap>
                                                        {user.level === 'TO' ? (
                                                            <Button
                                                                disabled={updateCheckedLoading}
                                                                size="small"
                                                                type="primary"
                                                                onClick={() =>
                                                                    submitSelectedItems({
                                                                        selected: true,
                                                                    })
                                                                }
                                                                icon={<CheckOutlined />}
                                                            >
                                                                Submit
                                                            </Button>
                                                        ) : (
                                                            <>
                                                                <Button
                                                                    disabled={updateCheckedLoading}
                                                                    size="small"
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        submitSelectedItems({
                                                                            approved: true,
                                                                        })
                                                                    }
                                                                    icon={<CheckOutlined />}
                                                                >
                                                                    Approve
                                                                </Button>
                                                                <Button
                                                                    disabled={updateCheckedLoading}
                                                                    size="small"
                                                                    type="primary"
                                                                    onClick={() =>
                                                                        submitSelectedItems({
                                                                            approved: false,
                                                                        })
                                                                    }
                                                                    icon={<CloseOutlined />}
                                                                    danger
                                                                >
                                                                    Reject
                                                                </Button>
                                                            </>
                                                        )}
                                                    </Space>
                                                    <Button type="primary" shape="circle">
                                                        {checkedList.length}
                                                    </Button>
                                                </>
                                            ) : null}
                                        </>
                                    </div>
                                ) : null}
                                {data?.data?.map((item, i) => (
                                    <Item
                                        checkList={checkedList}
                                        setCheckList={setCheckedList}
                                        loading={loading}
                                        data={item}
                                        key={i}
                                    />
                                ))}
                            </>
                        )}
                    </>
                ) : (
                    <div
                        style={{
                            minHeight: '200px',
                            width: '100%',
                            display: 'grid',
                            placeItems: 'center',
                        }}
                    >
                        <Spin size="large" />
                    </div>
                )}
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Pagination
                        size="large"
                        pageSize={totalShowPage}
                        showSizeChanger
                        showQuickJumper
                        current={currentPage}
                        defaultCurrent={1}
                        total={data?.totalCount}
                        onChange={onChange}
                        showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
                    />
                </div>
            </div>
        </>
    );
}

export default PmmMaintenance;
