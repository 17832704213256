/* eslint-disable prettier/prettier */
import { SearchOutlined } from '@ant-design/icons';
import { Button, Col, Input, Select } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
    empCode, empLevel,
    empName,
    empUsername
} from '../../redux/features/filter/cmMappingFilterSlice';

function EmployeeFilter({surveyDataFetch}) {
    const dispatch = useDispatch();

    return (
        <>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    placeholder="Emp Name"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(empName(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    placeholder="Emp Username"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(empUsername(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Select
                    placeholder="Emp Level"
                    size="large"
                    style={{
                        width: '100%',
                    }}
                    onChange={(e) => dispatch(empLevel(e))}
                    options={[
                        {
                            value: 'MS',
                            label: 'MS',
                        },
                        {
                            value: 'CM',
                            label: 'CM',
                        },
                    ]}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Input
                    placeholder="Emp Code"
                    size="large"
                    style={{ width: '100%' }}
                    onChange={(e) => dispatch(empCode(e.target.value))}
                />
            </Col>
            <Col xs={12} sm={8} md={6} lg={6} xl={6}>
                <Button
                    size="large"
                    className="filter-btn"
                    icon={<SearchOutlined />}
                    type="primary"
                    onClick={() => surveyDataFetch(1, 10, 'cleanShowResultOnPage')}
                >
                    Search
                </Button>
            </Col>
        </>
    );
}

export default EmployeeFilter;
