/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
import moment from 'moment';
import * as XLSX from 'xlsx';

export default async function getSovAiReport(data, token, onCompleted) {
    let res = [];
    try {
        onCompleted(false);

        let page = 0;
        while (++page) {
            const json = (
                await (
                    await fetch(
                        `https://api.hedigital.app/api/reports/getSOVAIReport?page=${page}&limit=10000`,
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(data),
                        }
                    )
                ).json()
            ).map((element) => element.row);
            if (!json.length) break;
            res = res.concat(json);
        }

        const wb = XLSX.utils.book_new();

        const ws = XLSX.utils.aoa_to_sheet(
            [
                [
                    'Date',
                    'Time',
                    'Region',
                    'Area',
                    'Distribution House',
                    'Territory',
                    'Point',
                    'Route/Section',
                    'CM Code',
                    'CM Name',
                    'MS Name',
                    'MS Code',
                    'Outlet Code',
                    'Outlet Name',
                    'Owner Name',
                    'Outlet Contact No',
                    'Channel',
                    'Sub Channel',
                    'TPG',
                    'Cluster',
                    'Cluster Type',
                    'Geo',
                    'Planogram',
                    'Partner Code',
                    'DCC Scope',
                    'Last Edit By',
                    ...['BATB', 'JTI', 'AKTC', 'PMI'].flatMap((c) => [c, null, null, null]),
                ],
                [
                    ...new Array(26).fill(null),
                    ...new Array(4)
                        .fill(null)
                        .flatMap((_, i) => ['Surface Area', 'Surface Area %', 'Count', 'Count%']),
                ],
            ].concat(res)
        );

        ws['!merges'] = [
            ...new Array(26).fill(null).map((_, i) => ({ s: { c: i, r: 0 }, e: { c: i, r: 1 } })),
            ...new Array(4).fill(null).map((_, i) => ({
                s: { c: 26 + i * 4, r: 0 },
                e: { c: 26 + (i + 1) * 4 - 1, r: 0 },
            })),
            ...new Array(16)
                .fill(null)
                .map((_, i) => ({ s: { c: 26 + i, r: 1 }, e: { c: 26 + i, r: 1 } })),
        ];

        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        XLSX.writeFile(wb, `SOV-Ai-Report_${moment().format('D-MMMM-YYYY-h-mm-A')}.xlsx`);
    } catch (error) {
        console.error(error);
    } finally {
        onCompleted(true);
        res = null;
    }
}
