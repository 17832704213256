/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable prettier/prettier */
import { Col, Row, Spin, theme } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetMMFilter } from '../../redux/features/filter/mmFilterSlice';
import NoResult from '../../ui/NoResult';
import Filter from '../Filter/Filter';
import PieChartGraph from './PieChart';
import TotalCovered from './TotalCovered';

function DashboardChart() {
  const dispatch = useDispatch();
    // reset existing filter
    useEffect(() => {
        dispatch(resetMMFilter());
    }, []);

    // survey filter data
    const { region, area, territory, distributionHouse, point } = useSelector(
        (state) => state.mmFilter
    );
    const { startDate, endDate } = useSelector(
        (state) => state.dashboardFilter
    );
    const { accessToken } = useSelector((state) => state.auth);


    // loading state
    const [loading, setLoading] = useState(false);

    // data state
    const [data, setData] = useState([]);

    // initial state load data
    useEffect(() => {
      setLoading(true);
      async function fetchData() {
        try {
          const promiseResponse = await fetch('https://api.hedigital.app/api/surveys/getDashboardData', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}` },
          })
          const result = await promiseResponse.json();
          setData(result);
        } catch (error) {
          console.log(error)
        } finally {
          setLoading(false);
        }
      }
      fetchData();
    }, [])

        // survey query data
        const getSurveyQueryData = () => {
          const bodyData = {};
  
          if (region.length) {
              bodyData.region = region?.map((r) => r.label);
          }
          if (area.length) {
              bodyData.area = area?.map((r) => r.label);
          }
          if (territory.length) {
              bodyData.territory = territory?.map((r) => r.label);
          }
          if (distributionHouse.length) {
              bodyData.distributionHouse = distributionHouse?.map((r) => r.label);
          }
          if (point.length) {
              bodyData.point = point?.map((r) => r.label);
          }
          if (startDate || endDate) {
              bodyData.updatedAt = [moment(startDate).toJSON() || '', moment(endDate).toJSON() || ''];
          }
          return bodyData;
      };

    const fetchSurveyQueryData = async () => {
      setLoading(true);
      try {
        const resultPromise = await fetch('https://api.hedigital.app/api/surveys/getDashboardData', {
          method: 'POST',
          headers: {'Content-Type': 'application/json', Authorization: `Bearer ${accessToken}`},
          body: JSON.stringify(getSurveyQueryData())
        })
        const result = await resultPromise.json();
        setData(result);
      } catch (error) {
        console.log(error)
      } finally {
        setLoading(false);
      }
    }

    const {
      token: { colorBgContainer },
  } = theme.useToken();

    return (
        <>
        <div style={{ margin: '16px 0' }}>
              <Filter surveyDataFetch={fetchSurveyQueryData} pathname="/" />
          </div>
        <div style={{
                    padding: 15,
                    minHeight: 400,
                    background: colorBgContainer,
                }}>
            {loading ? (
              <div style={{display: 'flex', width:'100%', height: '520px', justifyContent: 'center', alignItems: 'center'}}>
                <Spin size="large" />
              </div>
            ) : (
              <>
                {!data?.regionWiseOutletCovered?.length && !data?.scopeVSCovered  ? (
                  <div>
                    <NoResult />
                  </div>
                ) : (
                  <Row>
                    <Col lg={15} md={24} className="chart-col-container">
                      <div>
                        <h2 className="chart-header-title">Outlet Covered</h2>
                        <TotalCovered data={data.regionWiseOutletCovered} />
                      </div>
                    </Col>
                    <Col lg={1}/>
                    <Col lg={8} md={24} className="chart-col-container">
                      <h2 className="chart-header-title">Scope vs Covered</h2>
                      <PieChartGraph pieData={data?.scopeVSCovered} />
                    </Col>
                  </Row>
                )}
              </>
            )}
        </div>
        </>
    );
}

export default DashboardChart;
