/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    empName: '',
    empUsername: '',
    empLevel: '',
    empCode: '',
};

const cmMappingFilterSlice = createSlice({
    name: 'cmMappingFilterSlice',
    initialState,
    reducers: {
        empName: (state, action) => {
            state.empName = action.payload;
        },
        empUsername: (state, action) => {
            state.empUsername = action.payload;
        },
        empLevel: (state, action) => {
            state.empLevel = action.payload;
        },
        empCode: (state, action) => {
            state.empCode = action.payload;
        },
        resetMappingFilter: (state, action) => {
            state.empName = '';
            state.empUsername = '';
            state.empCode = '';
        },
    },
});

export default cmMappingFilterSlice.reducer;
export const { empName, empUsername, empLevel, empCode, resetMappingFilter } =
    cmMappingFilterSlice.actions;
